import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, } from "@angular/forms";
import { User } from "./user";
import { MatSnackBar } from "@angular/material";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { Constants } from "../../environments/environment";
import { environment, RESPONSIBILITIES } from "../../environments/environment";
import { LoginModalComponent } from "./modal/login-modal.component";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
export class LoginComponent {
    constructor(route, homeService, router, loginService, matSnackBar, commonService, digitalService, formBuilder, carouselConfig, loaderService, translate, dialog, toastrService) {
        this.route = route;
        this.homeService = homeService;
        this.router = router;
        this.loginService = loginService;
        this.matSnackBar = matSnackBar;
        this.commonService = commonService;
        this.digitalService = digitalService;
        this.formBuilder = formBuilder;
        this.carouselConfig = carouselConfig;
        this.loaderService = loaderService;
        this.translate = translate;
        this.dialog = dialog;
        this.toastrService = toastrService;
        this.pwdValidationHighLight = true;
        this.forgotPasswordErrorMsg = "";
        this.submitted = false;
        this.term = false;
        this.filter = false;
        this.termsAndConditionADLogin = false;
        this.termsAndConditionForAdLoginPage = false;
        this.check = false;
        this.termsVal = true;
        this.frgtPasswordRequest = {};
        this.bothInvalid = false;
        this.emailRequired = false;
        this.pwdRequired = false;
        this.showLoginPage = false;
        this.languageShow = true;
        this.inputType = "password";
        this.loginEmail = true;
        this.loginEmailNext = false;
        this.loginPassword = false;
        this.loginDomain = false;
        this.listOfDomains = [];
        this.isSubDomainsLogin = false;
        this.loginReenterEmail = false;
        this.artDomains = [];
        this.isDigital = false;
        this.isDigitalLogin = false;
        this.digitalLoginFail = false;
        this.digitalLoginError = "";
        this.oAuthLogin = false;
        this.logoUrl = "";
        this.isMultiLoginDigital = false;
        this.unsubscribe$ = new Subject();
        this.samlLogin = false;
        this.loading = false;
        this.user = new User();
        this.forgotPasswordRequest = {};
        this.requestOtpSuccess = false;
        this.vmsPermissions = [];
        this.baseLogin = true;
        this.forgotPasswordRequired = true;
        this.domainLists = [];
        this.codeLogin = false;
        this.appConfig = {};
        this.resolved = (event) => {
            this.captchToken = event;
        };
        this.storeLoggedUser = (user) => {
            localStorage.setItem("ssadmin_userImg", user["imageUrl"]);
            localStorage.setItem("ssadmin_session", user["sessionId"]);
            localStorage.setItem("ssadmin_userId", user["userId"]);
            localStorage.setItem("ssadmin_user", user["email"]);
            localStorage.setItem("ssadmin_name", user["name"]);
            localStorage.setItem("ssadmin_phonenumber", user["phoneNum"]);
            localStorage.setItem("ssadmin_tenantId", user["tenantId"]);
            localStorage.setItem("ssadmin_deptId", user["deptId"]);
            localStorage.setItem("ssadmin_enterpriseName", user["enterpriseName"]);
            localStorage.setItem("ssadmin_digitalversion", this.isDigital.toString());
            localStorage.setItem("ssadmin_department", user["departName"]); // storing department for workforce management profile tab to show logged in user department
            localStorage.setItem("ssadmin_locationsOnboarded", user["locationsOnboarded"]);
            localStorage.setItem("ssadmin_globalAdmin", user["globalAdmin"]);
            localStorage.setItem("ssadmin_globalResponsibilities", user["globalResponsibilities"]);
            localStorage.setItem("ssadmin_firstTimeLogin", user["firstTimeLogin"]);
            if (!user["globalAdmin"] && !!user["zonesMap"] && Object.keys(user["zonesMap"]).length > 0) {
                // localStorage.setItem("currentZone", Object.keys(user["zonesMap"])[0]);
            }
            return;
        };
        translate.setDefaultLang("en");
        let currentContext = this;
        this.userForm = this.formBuilder.group({
            email: [this.user.email, [Validators.required, Validators.email]],
            password: [this.user.password, Validators.required],
            acceptTerms: [false, Validators.requiredTrue],
            adDomain: [this.user.adDomain],
        });
        this.carouselConfig.wrap = true;
        this.carouselConfig.interval = 1500;
        this.carouselConfig.keyboard = false;
        //this.carouselConfig.pauseOnHover = true;
        if (Constants.SAML_LOGIN_REQUIRED) {
            this.samlLogin = true;
            window.location.href = environment.BASE_URL + "emsPru/user.html";
        }
        else if (Constants.OAUTH_LOGIN_REQUIRED) {
            this.oAuthLogin = Constants.OAUTH_LOGIN_REQUIRED;
            this.samlLogin = false;
            /*
                  this.commonService.getConfigByModuleId(48).subscribe( res => {
                      currentContext.appConfig = (res as any).response ? (res as any).response : res;
                      currentContext.appConfig = JSON.parse(currentContext.appConfig);
                      currentContext.oAuthLoginUrl = currentContext.appConfig.ems.login.oAuthLoginUrl;
                  }, err => {
                      this.commonService.openSnackBar(err.error.message, '');
                  })*/
        }
        this.imagePath = "/assets/images/jumpreelogo.png";
        this.commonService.getConfigByModuleId(48).subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            // localStorage.setItem('config48', JSON.stringify(res));
            currentContext.appConfig = res.response
                ? res.response
                : res;
            currentContext.appConfig = JSON.parse(currentContext.appConfig);
            //For chekcking digital or 3.5  
            if (this.appConfig["ems"]["logoUrl"] &&
                this.appConfig["ems"]["logoUrl"] != undefined) {
                this.logoUrl = this.appConfig["ems"]["logoUrl"];
                console.log(this.logoUrl);
            }
            if (currentContext.appConfig && currentContext.appConfig["dynamicSiteNavBar"] && currentContext.appConfig["dynamicSiteNavBar"] == true) {
                localStorage.setItem("dynamicSiteNavBar", "true"); //Schedule Demo flow enabled (Curated services)
            }
            if (!!currentContext.appConfig["version"] &&
                (currentContext.appConfig["version"]["deploymentType"] == "TEAMS" ||
                    currentContext.appConfig["version"]["deploymentType"] == "DIGITAL")) {
                localStorage.removeItem("ssadmin_enterpriseName"); //Removing for new enterprise name for digital
                currentContext.isDigitalLogin = true;
                this.digitalService.globalVersionCheck(true);
                if (currentContext.appConfig["version"]["deploymentType"] != "DIGITAL") {
                    this.digitalService.globalJumpreeProVerisonCheck(false);
                }
                else {
                    this.digitalService.globalJumpreeProVerisonCheck(true);
                }
                currentContext.isDigital = true;
            }
            else {
                this.digitalService.globalVersionCheck(false);
            }
            let domains = currentContext.appConfig.ems.login.appLoginDetails;
            if (currentContext.appConfig && typeof currentContext.appConfig['artDomains'] != "undefined") {
                this.artDomains = currentContext.appConfig['artDomains'];
            } // domains = domains.forEach((elm)=>{
            //     console.log(Object.Keys(elm))
            // } )
            if (domains && domains.length > 0) {
                domains.map((item) => {
                    let aKeys = Object.keys(item);
                    if (aKeys.includes("subDomainsLogin")) {
                        currentContext.isSubDomainsLogin = true;
                    }
                    if (aKeys.includes("MultiLoginDigital")) {
                        if (item["MultiLoginDigital"].MultiLoginDigital == true)
                            currentContext.isMultiLoginDigital = true;
                    }
                });
            }
            if (currentContext.isSubDomainsLogin && domains && domains.length > 0) {
                domains.map((item, index) => {
                    let aKeys = Object.keys(item);
                    if (aKeys.includes("subDomainsLogin")) {
                        currentContext.listOfDomains =
                            domains[index]["subDomainsLogin"]["dropdownvalues"];
                    }
                });
                // currentContext.listOfDomains = domains[2].subDomainsLogin.dropdownvalues
            }
            this.termsAndConditions =
                currentContext.appConfig["help"]["termsAndConditions"];
            if (typeof currentContext.appConfig["header_logo"] != "undefined") {
                currentContext.headerLogo = "../../../assets/images/jumpreelogo.png";
            }
            else {
                currentContext.headerLogo = "../../../assets/images/jumpreelogo.png";
            }
            if ((currentContext.appConfig &&
                currentContext.appConfig.ems &&
                currentContext.appConfig.ems.login &&
                currentContext.appConfig.ems.login.oAuthLoginRequired) ||
                (currentContext.isSubDomainsLogin || currentContext.isDigitalLogin)) {
                this.oAuthLogin =
                    currentContext.appConfig.ems.login.oAuthLoginRequired;
                currentContext.oAuthLoginUrl =
                    currentContext.appConfig.ems.login.oAuthLoginUrl;
                const queryString = new URLSearchParams(currentContext.appConfig.ems.login.oAuthLoginUrl);
                let requestParam = "code";
                if (queryString.has("response_type")) {
                    requestParam = queryString.get("response_type");
                }
                localStorage.setItem("requestParam", requestParam);
                this.routeUrl();
            }
            else {
                this.routeUrl();
            }
            if (typeof currentContext.appConfig["captcha_enabled"] != "undefined" &&
                !!currentContext.appConfig["captcha_enabled"]) {
                this.showCaptcha = true;
            }
            this.showLoginPage = true;
        }), (err) => {
            try {
                this.commonService.openSnackBar(err.error.message, "");
            }
            catch (error) {
                this.commonService.openSnackBar(err, "");
            }
            this.imagePath = "/assets/images/Smarten-Spaces-logo-login.png";
            currentContext.headerLogo = "../../../assets/images/jumpreelogo.png";
        });
    }
    ngOnInit() {
        //localStorage.clear();
        var currentContext = this;
        localStorage.removeItem("ssadmin_session");
        localStorage.removeItem("ssadmin_userId");
        localStorage.removeItem("currentZone");
        localStorage.removeItem('defaultZone');
        let keysToRemove = [];
        for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            // Condition to check if item should be removed
            if (key != 'requestParam' && key != 'language') {
                keysToRemove.push(key);
            }
            if (i + 1 == localStorage.length) {
                for (let j = 0; j < keysToRemove.length; j++) {
                    localStorage.removeItem(keysToRemove[j]);
                }
            }
        }
        // this block is necessary to display sucessfully account verified user/tenant on login screen only
        this.route.params.subscribe((params) => {
            currentContext.accountVerifyMessage = params["msg1"];
            currentContext.accountVerifiedName = params["msg2"];
        });
        this.route.queryParams.subscribe((params) => {
            let requestParam = localStorage.getItem("requestParam");
            let urlFuj = environment.BASE_URL;
            if ((requestParam == null && urlFuj.includes("fujitsu")) ||
                params["isTeamsLogin"]) {
                requestParam = "code";
            }
            if (params[requestParam]) {
                this.loaderService.loadingOn();
                this.codeLogin = true;
                this.isSubDomainsLogin = true;
                this.languageShow = false;
                this.getUserDetailForMS(params[requestParam], params);
            }
        });
        //localStorage.clear();
        this.forgotPasswordRequired = Constants.FORGOT_PASSWORD_REQUIRED;
    }
    routeUrl() {
        let urlFormHost = environment.BASE_URL;
        if (urlFormHost.includes("devqa") || urlFormHost.includes("hswdb") || urlFormHost.includes("mdlz") || urlFormHost.includes("mdlzeu") || urlFormHost.includes("digitalpoc") || urlFormHost.includes("digitalvapt") || urlFormHost.includes("nissan") || urlFormHost.includes("jumpreedigital") || urlFormHost.includes("jumpreeusdigital") || urlFormHost.includes("digitalstaging") || urlFormHost.includes("digitalqa") || urlFormHost.includes("syngenta")) {
            this.languageSelectionGet();
            return;
        }
        else if (this.oAuthLogin) {
            this.route.queryParams.subscribe((params) => {
                if (Object.keys(params).length === 0) {
                    this.termsAndConditionForAdLoginPage = true;
                    //  window.location.href = this.oAuthLoginUrl;
                }
            });
        }
    }
    ngAfterViewInit() {
        var currentContext = this;
        if (localStorage.getItem('config48')) {
            this.appConfigData(JSON.parse(localStorage.getItem('config48')));
        }
        else {
            this.commonService.getConfigNewDomainFirstSource(5, 48).subscribe((res) => {
                this.appConfigData(res);
            }, (err) => {
                this.commonService.openSnackBar(err.error.message, "");
            });
        }
        // this.commonService.getConfigNewDomainFirstSource(5, 48).subscribe(
        //   (res) => {
        //     currentContext.appConfig = (res as any).response
        //       ? (res as any).response
        //       : res;
        //     currentContext.appConfig = JSON.parse(currentContext.appConfig);
        //     this.domainLists = currentContext.appConfig.adDomains;
        //     this.user.adDomain = !!this.domainLists
        //       ? this.domainLists[0].domain
        //       : "Select here";
        //   },
        //   (err) => {
        //     this.commonService.openSnackBar(err.error.message, "");
        //   }
        // );
    }
    appConfigData(res) {
        let currentContext = this;
        currentContext.appConfig = res.response
            ? res.response
            : res;
        currentContext.appConfig = JSON.parse(currentContext.appConfig);
        this.domainLists = currentContext.appConfig.adDomains;
        this.user.adDomain = !!this.domainLists
            ? this.domainLists[0].domain
            : "Select here";
    }
    languageSelectionGet() {
        var currentContext = this;
        this.loginService.languageSelection(50).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
            let languageList = res.response
                ? res.response
                : res;
            let data = {
                type: 'language',
                listLanguage: languageList
            };
            let languageLocal = localStorage.getItem('language');
            if (!languageLocal) {
                this.languageShow = false;
                currentContext.openDialog(data, '850px');
            }
            else {
                this.translate.use(languageLocal);
                this.route.queryParams.subscribe((params) => {
                    if (Object.keys(params).length === 0) {
                        if (this.oAuthLogin) {
                            this.termsAndConditionForAdLoginPage = true;
                            //window.location.href = this.oAuthLoginUrl;
                        }
                    }
                });
            }
        }, err => {
            this.commonService.openSnackBar(err.error.message, "");
        });
    }
    changeLogin() {
        this.baseLogin = false;
    }
    getUserDetailForMS(code, params) {
        let currentContext = this;
        let postObj = {};
        if (params["isTeamsLogin"]) {
            postObj = { authCode: code, isTeamsLogin: true };
        }
        else {
            postObj = { authCode: code };
        }
        currentContext.loading = true;
        this.loginService.login(postObj).subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            let isGlobalLogin = false;
            if (res["response"] && res["response"]["defaultZoneId"] != null) {
                this.commonService.changeDefaultZone(res["response"]["defaultZoneId"]);
            }
            if (res["response"]["globalAdmin"]) {
                isGlobalLogin = res["response"]["globalAdmin"];
            }
            this.commonService.globalAdmin(isGlobalLogin);
            document.cookie = `isGlobalAdmin=${isGlobalLogin}`;
            yield this.storeLoggedUser(res["response"]);
            localStorage.setItem("ssadmin_token", res["response"]["tokenId"]);
            //localStorage.setItem("currentZone", "5");
            localStorage.removeItem("requestParam");
            currentContext.loading = false;
            //currentContext.router.navigate(["/layout/digital-dashboard"]);
            /*Dont remove this code will be useful for 3.5 and digital -- venkat */
            if (currentContext.isDigital) {
                currentContext.router.navigate(["/layout/digital-dashboard"]);
            }
            else {
                currentContext.router.navigate(["/layout"]);
            }
        }), (err) => {
            //this.commonService.openSnackBar(err.error.message, "");
            this.loaderService.loadingOff();
            //if(this.isDigital){
            this.digitalLoginFail = true;
            this.digitalLoginError = err.error.message;
            //}
        });
    }
    getTranslate(word) {
        let translatedWord = word;
        const test = this.translate.get([word]).subscribe(translation => {
            translatedWord = translation;
        });
        return translatedWord[word];
    }
    closeValidation() {
        let currentContext = this;
        currentContext.forgotPasswordErrorMsg = "";
    }
    changeValue() {
        if (this.filter == false) {
            this.term = false;
            this.check = true;
        }
        else {
            this.term = true;
            this.check = false;
        }
    }
    clickToProceedAdLogin() {
        if (this.termsAndConditionADLogin) {
            window.location.href = this.oAuthLoginUrl;
        }
        else {
            this.toastrService.error(this.translate.instant("kindlyaccepttheTermsConditions"));
        }
    }
    checkValidation(user) {
        if (user.email == "" && user.password == "") {
            this.bothInvalid = true;
        }
        else {
            this.bothInvalid = false;
        }
        if (user.email == "" && user.password != "") {
            this.emailRequired = true;
        }
        else {
            this.emailRequired = false;
        }
        if (user.email != "" && user.password == "") {
            this.pwdRequired = true;
        }
        else {
            this.pwdRequired = false;
        }
    }
    get f() {
        return this.userForm.controls;
    }
    login(user) {
        this.checkValidation(user);
        let currentContext = this;
        currentContext.forgotPasswordErrorMsg = "";
        if (currentContext.appConfig &&
            currentContext.appConfig.ems &&
            currentContext.appConfig.ems.login &&
            currentContext.appConfig.ems.login.oAuthLoginRequired) {
            window.location.href = currentContext.oAuthLoginUrl;
            return;
        }
        this.submitted = true;
        // stop here if form is invalid
        if (this.userForm.invalid && !this.isSubDomainsLogin && !this.isDigitalLogin) {
            if (this.check == false) {
                this.term = true;
            }
            else {
                this.term = false;
            }
            return;
        }
        if (!(user.email && user.password)) {
            if (currentContext.pwdValidationHighLight == true) {
                currentContext.forgotPasswordErrorMsg = this.translate.instant("bothEmailPasswordReq");
                setTimeout(() => {
                    currentContext.forgotPasswordErrorMsg = "";
                }, 3000);
            }
            else {
                currentContext.commonService.openSnackBar(this.translate.instant("bothEmailPasswordReq"), "");
            }
        }
        else {
            if (this.captchToken) {
                user["captchaToken"] = this.captchToken;
            }
            const login$ = currentContext.loginService.login(user);
            this.loaderService.showLoaderUntilCompleted(login$).subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (res) {
                    this.response = res;
                    yield this.storeLoggedUser(res["response"]);
                    if (this.response["response"] && this.response["response"]["defaultZoneId"] != null) {
                        this.commonService.changeDefaultZone(this.response["response"]["defaultZoneId"]);
                    }
                    if (res["response"]["verificationEntity"] != null) {
                        let data = {
                            "type": "verifyOtp",
                            'userId': res["response"]["userId"],
                            'verficationEntity': res["response"]["verificationEntity"]
                        };
                        currentContext.openDialog(data, '850px');
                    }
                    else {
                        this.loginNextFunction(res);
                    }
                }
                else {
                    if (currentContext.pwdValidationHighLight == true) {
                        currentContext.forgotPasswordErrorMsg = res["message"];
                        setTimeout(() => {
                            currentContext.forgotPasswordErrorMsg = "";
                        }, 3000);
                    }
                    else {
                        currentContext.commonService.openSnackBar(res["message"], "");
                    }
                    currentContext.router.navigate(["/login"]);
                }
            }), function (err) {
                if (currentContext.pwdValidationHighLight == true) {
                    currentContext.forgotPasswordErrorMsg = err["error"]["message"];
                    currentContext.toastrService.error(err["error"]["message"]);
                }
                else {
                    currentContext.toastrService.error(err["error"]["message"]);
                }
            });
        }
    }
    loginNextFunction(res) {
        let currentContext = this;
        let zoneMap = Object.keys(res["response"]["zonesMap"]);
        let userResponsibilities = res["response"]["responsibilities"];
        let sm_responsibility = RESPONSIBILITIES;
        currentContext.router.navigate(["/layout"]);
    }
    openDialog(data, widthSize) {
        this.samlLogin = true;
        if (!this.dialogRef) {
            this.dialogRef = this.dialog.open(LoginModalComponent, {
                width: widthSize,
                height: "auto",
                panelClass: "ws-user-custom-info-dialog",
                data: data,
                disableClose: true,
            });
            this.dialogRef.afterClosed().subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (result) {
                    yield this.storeLoggedUser(result["response"]);
                    if (result) {
                        this.loginNextFunction(result);
                    }
                    this.dialogRef = false;
                    this.declarativeFormCaptchaValue = "";
                    this.samlLogin = false;
                    this.languageShow = true;
                }
                else {
                    this.samlLogin = false;
                    this.languageShow = true;
                    if (this.oAuthLogin) {
                        this.termsAndConditionForAdLoginPage = true;
                        //window.location.href = this.oAuthLoginUrl;
                    }
                }
            }));
        }
    }
    requestOtp(user) {
        var currentContext = this;
        if (!user.email) {
            currentContext.commonService.openSnackBar(this.translate.instant("emailRequiredForLogin"), "");
        }
        else {
            currentContext.loginService.requestOtp(user).subscribe((res) => {
                currentContext.requestOtpSuccess = true;
            }, (err) => {
                this.commonService.openSnackBar(this.translate.instant("errorredOccuredSendingOutOTP") + user.email, "");
            });
        }
    }
    otpLogin(user) {
        var currentContext = this;
        if (!(user.email && user.otp)) {
            currentContext.commonService.openSnackBar(this.translate.instant("bothEmailAndOTPAreRequired"), "");
        }
        else {
            currentContext.loginService.otpLogin(user).subscribe((res) => {
                if (res) {
                    localStorage.setItem("ssadmin_session", res["response"]["sessionId"]);
                    localStorage.setItem("ssadmin_userId", res["response"]["userId"]);
                    localStorage.setItem("ssadmin_user", res["response"]["email"]);
                    localStorage.setItem("ssadmin_name", res["response"]["name"]);
                    localStorage.setItem("userObj", JSON.stringify(res["response"]));
                    currentContext.router.navigate(["/layout"]);
                }
                else {
                    currentContext.commonService.openSnackBar(res["message"], "");
                    currentContext.router.navigate(["/login"]);
                }
            }, function (err) {
                currentContext.commonService.openSnackBar(err.error.message, "");
            });
        }
    }
    forgotPassword(user) {
        if (!user.email) {
            this.commonService.openSnackBar(this.translate.instant("emailIDIsRequiredToResetPassword"), "");
        }
        this.forgotPasswordRequest = {
            email: user.email,
        };
        this.loginService
            .sendForgotPasswordEmail(this.forgotPasswordRequest)
            .subscribe((res) => {
            localStorage.setItem("forgotPassword_emailId", user.email);
            this.commonService.openSnackBar(this.translate.instant("resetPasswordLinkSentYourEmail") + user.email, "");
            this.router.navigate(["/login"]);
        }, (err) => {
            this.commonService.openSnackBar(err.error.message, "");
            this.router.navigate(["/login"]);
        });
    }
    forgotPasswordNew(user) {
        this.router.navigate(["/frgt-password"]);
    }
    get passwordInvalid() {
        const control = this.userForm.get("password");
        return control.hasError("required") && control.touched;
    }
    get emailFormat() {
        const control = this.userForm.get("email");
        return control.hasError("email") && control.touched;
    }
    get terms() {
        const control = this.userForm.get("acceptTerms");
        return control.hasError("acceptTerms");
    }
    loginProceed() {
        let currentContext = this;
        let emailId = this.user.email;
        let selectedEMail = this.user.email;
        ;
        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let selectedEMailDomaian = selectedEMail.split('@').pop();
        let domainValidation = this.artDomains.length > 0 ? this.artDomains.includes(selectedEMailDomaian) ? true : false : true;
        if (!!emailId && emailId.match(validRegex) && domainValidation) {
            if (this.listOfDomains.length == 1 || this.isDigitalLogin) {
                if (this.listOfDomains.length == 1) {
                    let domainTypeVal = this.listOfDomains[0].loginType;
                    if (domainTypeVal.includes("DB")) {
                        this.loginEmail = false;
                        this.loginEmailNext = false;
                        if (this.isSubDomainsLogin && this.isMultiLoginDigital) {
                            this.loginDomain = true;
                            this.loginPassword = false;
                        }
                        else {
                            this.loginPassword = true;
                            this.loginDomain = false;
                        }
                    }
                    else {
                        let selectedDomain = this.listOfDomains;
                        window.location.href = selectedDomain[0].loginUrl;
                        return;
                    }
                }
                else {
                    if (!this.oAuthLogin) {
                        this.loginEmail = false;
                        this.loginEmailNext = false;
                        if (this.isSubDomainsLogin && this.isMultiLoginDigital) {
                            this.loginDomain = true;
                            this.loginPassword = false;
                        }
                        else {
                            this.loginPassword = true;
                            this.loginDomain = false;
                        }
                    }
                    else {
                        window.location.href = this.oAuthLoginUrl;
                        return;
                    }
                }
            }
            else {
                this.loginEmail = false;
                this.loginEmailNext = false;
                this.loginPassword = !this.isSubDomainsLogin;
                this.loginDomain = this.isSubDomainsLogin;
            }
        }
        else {
            if (!!emailId) {
                this.toastrService.error(this.translate.instant("pleaseEnterValidEmailID"));
            }
            else {
                this.toastrService.error(this.translate.instant("pleaseEnterEmailID"));
            }
        }
    }
    loginPasswordProceed() {
        let passwordValid = this.user.password;
        if (this.filter == true && !!passwordValid) {
            this.login(this.user);
        }
        else {
            if (!passwordValid) {
                this.toastrService.error(this.translate.instant("passwordRequired"));
            }
            else {
                this.toastrService.error(this.translate.instant("kindlyaccepttheTermsConditions"));
            }
        }
    }
    loginemailNext() {
        this.frgtPasswordRequest["email"] = this.user.email;
        this.loginEmail = false;
        this.loginEmailNext = true;
        this.loginPassword = false;
        this.loginDomain = false;
        this.user.email = "";
    }
    loginPasswprdResend() {
        let currentContext = this;
        let emailId = this.user.email;
        this.frgtPasswordRequest["email"] = this.user.email;
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!!emailId && emailId.match(validRegex)) {
            this.loginService.sendResetPasswordEmailLink(this.frgtPasswordRequest);
            const forgetPassword$ = this.loginService.sendResetPasswordEmailLink(this.frgtPasswordRequest);
            this.loaderService.showLoaderUntilCompleted(forgetPassword$).subscribe((res) => {
                this.toastrService.success(res["message"]);
                this.loginEmail = true;
                this.loginEmailNext = false;
                this.loginPassword = false;
                this.loginDomain = false;
                this.loginReenterEmail = false;
            }, (err) => {
                this.commonService.openSnackBar(err.error.message, "");
            });
        }
        else {
            if (!!emailId) {
                this.toastrService.error(this.translate.instant("pleaseEnterValidEmailID"));
            }
            else {
                this.toastrService.error(this.translate.instant("pleaseEnterEmailID"));
            }
        }
    }
    resendEmail() {
        this.frgtPasswordRequest["email"] = this.user.email;
        this.loginService.sendResetPasswordEmailLink(this.frgtPasswordRequest);
        const forgetPassword$ = this.loginService.sendResetPasswordEmailLink(this.frgtPasswordRequest);
        this.loaderService.showLoaderUntilCompleted(forgetPassword$).subscribe((res) => {
            this.toastrService.success(res["message"]);
        }, (err) => {
            this.commonService.openSnackBar(err.error.message, "");
        });
    }
    loginFirst() {
        let domainType = this.user.adDomain;
        if (!this.filter) {
            this.toastrService.error(this.translate.instant("kindlyaccepttheTermsConditions"));
            return;
        }
        let domainTypeVal = this.listOfDomains[0].loginType;
        if (domainType !== "Select here") {
            if (domainType.includes("DB") ||
                (this.listOfDomains.length == 1 && domainTypeVal.includes("DB"))) {
                this.loginEmail = false;
                this.loginEmailNext = false;
                this.loginPassword = true;
                this.loginDomain = false;
                this.loginReenterEmail = false;
            }
            else {
                let selectedDomain = this.listOfDomains;
                selectedDomain = selectedDomain.filter((elm) => elm.loginType == domainType);
                window.location.href = selectedDomain[0].loginUrl;
                return;
            }
        }
        else {
            this.toastrService.error(this.translate.instant("pleaseMakeTheSelection"));
        }
    }
    showPasswordChange(type) {
        this.inputType = type;
    }
    backButton(type) {
        this.user.adDomain = this.translate.instant("selectHereSml");
        this.filter = false;
        if (type == "login") {
            this.loginEmail = true;
            this.loginEmailNext = false;
            this.loginPassword = false;
            this.loginDomain = false;
        }
        else if (type == "password") {
            this.loginEmail = true;
            this.loginEmailNext = false;
            this.loginPassword = false;
            this.loginDomain = false;
        }
    }
    ngOnDestroy() {
        if (this.unsubscribe$) {
            this.unsubscribe$.next();
            this.unsubscribe$.complete();
        }
    }
}
