import * as tslib_1 from "tslib";
import { ElementRef, OnInit, NgZone, } from "@angular/core";
import { FormControl, FormGroup, Validators, } from "@angular/forms";
import { MatDialogRef, MatDialog } from "@angular/material";
import { EMPTY, forkJoin, of, Subject } from "rxjs";
import { catchError, distinctUntilChanged, map, mergeMap, startWith, switchMap, takeUntil, tap, } from "rxjs/operators";
import * as geolib from "geolib";
import * as moments from "moment-timezone";
import { environment } from "src/environments/environment";
const theGeolib = geolib.default;
export { theGeolib as geolib };
const place = null;
export class GlobalSetupModalComponent {
    constructor(dialogRef, data, dialog, toaster, formBuilder, onboardingService, globalSetupService, commonService, loaderService, apiService, ngZone, digitalService, translate, emsService, excelService) {
        /*
    this.commonService.getConfigByModuleId(48).subscribe(
      async (res) => {
        this.appConfig = (res as any).response
          ? (res as any).response
          : res;
        this.appConfig = JSON.parse(this.appConfig);
        if(this.appConfig['ems']['isBetaMessage'] != undefined && this.appConfig['ems']['isBetaMessage']==true){
          this.isShowableTrial=true;
        }
        else{
          this.isShowableTrial = false;
        }
      });
    */
        this.dialogRef = dialogRef;
        this.data = data;
        this.dialog = dialog;
        this.toaster = toaster;
        this.formBuilder = formBuilder;
        this.onboardingService = onboardingService;
        this.globalSetupService = globalSetupService;
        this.commonService = commonService;
        this.loaderService = loaderService;
        this.apiService = apiService;
        this.ngZone = ngZone;
        this.digitalService = digitalService;
        this.translate = translate;
        this.emsService = emsService;
        this.excelService = excelService;
        this.selectDisableUser = null;
        this.addLocNonTeams = false;
        this.showInfo = false;
        this.submitted = false;
        this.addViaExcelNew = false;
        this.submittedDepartment = false;
        this.countryList = [];
        this.stateList = [];
        this.cityList = [];
        this.departmentList = [];
        this.LOCATIONS_ELEMENT_DATA = [];
        this.DEPARTMENT_ELEMENT_DATA = [];
        this.stateCtrl = new FormControl();
        this.cityCtrl = new FormControl();
        this.timeZoneCtrl = new FormControl();
        this.countryCtrl = new FormControl();
        this.countLocation = 0;
        this.addUserType = "";
        this.templateDownloaded = false;
        this.templateData = [];
        this.correctData = [];
        this.incorrectData = [];
        this.saveCorrectData = [];
        this.showIncorrectData = [];
        this.noOfEntitiesUploaded = 0;
        this.pageSize = 10;
        this.totalIncorrectLength = 10;
        this.bulkUploadedData = [];
        this.proceedToUploadBtn = false;
        this.globalUsers = [];
        this.uploadedFileName = "";
        this.displayedColumnsIncorrectData = [];
        this.countryCodeList = [];
        this.unsubscribe$ = new Subject();
        this.isReadonlyState = true;
        this.isReadonlyCity = true;
        this.addLocationType = "";
        this.addDepartmentType = "";
        this.submittedLocationType = false;
        this.submittedDepartmentType = false;
        this.submittedUsers = false;
        this.submittedUserType = false;
        this.fileType = "";
        this.readOnly = false;
        this.selectedloc = new FormControl();
        this.selectedRol = new FormControl();
        this.pageCount = 1;
        this.individual = false;
        this.jumpreeProNonTeams = false;
        this.locSelected = [];
        this.objUserRoles = [
            {
                rolesid: "",
                locationid: [],
            },
        ];
        this.displayIncorrectUserDatas = [
            // "slNo",
            "employeeName",
            "department",
            "email",
            "globalAdmin",
            "location",
            "additionalRole",
            "reason",
        ];
        this.searchControl = new FormControl("");
        this.searchLocation = new FormControl("");
        this.searchLocationNonTeams = new FormControl("");
        this.searchLocation2NonTeams = new FormControl("");
        this.searchRoleNonTeams = new FormControl("");
        this.departName = new FormControl("");
        this.roles = [
            {
                role: "General User",
                category: [
                    {
                        privileges: "Dashboard",
                        roles: ["Booking \n (My Bookings)", "Work status", "FAQ's"],
                    },
                    {
                        privileges: "App",
                        roles: ["Booking", "Work status"],
                    },
                ],
            },
            {
                role: "Global Admin",
                category: [
                    {
                        privileges: "Dashboard",
                        roles: [
                            "Global Setup",
                            "All Locations \n (Super Admin)",
                            "Subscriptions",
                            "Space Planning",
                            "General User \n (All locations)",
                        ],
                    },
                ],
            },
            {
                role: "Super Admin",
                category: [
                    {
                        privileges: "Dashboard",
                        roles: [
                            "People",
                            "Groups",
                            "Manage Bookings",
                            "Bookings \n (My Bookings)",
                            "Work Status",
                            "Space Planning",
                            "FAQs",
                            "Settings",
                            "Integrations",
                            "General User \n (All locations)",
                        ],
                    },
                    {
                        privileges: "App",
                        roles: ["Booking", "Work Status"],
                    },
                ],
            },
            // {role:"Super Admin",category:[{privileges:"App",roles:["Booking Pro","Hybrid Scheduler"]},{privileges:"Dashboard",roles:["Booking Pro (My Bookings)","Hybrid Scheduler","FAQs"]}]},
            {
                role: "Booking Admin",
                category: [{ privileges: "Dashboard", roles: ["Manage Bookings"] }],
            },
            {
                role: "Integrations Admin",
                category: [
                    { privileges: "Dashboard", roles: ["Manage Integrations"] },
                ],
            },
            {
                role: "People",
                category: [
                    {
                        privileges: "Dashboard",
                        roles: ["People"],
                    },
                ],
            },
        ];
        this.displayPerPage = 4;
        this.totalPages = 0;
        this.entries = [];
        this.locationFields = [
            "name",
            "cityName",
            "stateCode",
            "countryName",
            "countryCode",
        ];
        this.userName = "";
        this.editDeptData = {};
        this.clientName = "";
        this.closeDialogAddMoreUsers = "";
        this.allSiteAccessUsers = false;
        this.autoAssignUsers = false;
        this.nameSearchIsLoading = false;
        this.locationsListNonTeams = [];
        this.customLocationList = [];
        this.additionalRoles = new FormControl(false);
        this.accessAllLocationsNonTeams = true;
        this.nameSearchTermSubject$ = new Subject();
        this.nameSearchTermAction$ = this.nameSearchTermSubject$.asObservable();
        this.nameAutocompleteList$ = this.nameSearchTermAction$.pipe(tap(() => {
            this.nameSearchIsLoading = true;
        }), switchMap((searchTerm) => of(searchTerm).pipe(mergeMap((res) => this.globalSetupService.getEmployeeNameAutoCompleteList({
            searchTerm,
            searchType: this.eName,
        }, this.currentZone)))), tap((searchRes) => {
            this.nameSearchIsLoading = false;
        }), catchError((err) => {
            return EMPTY;
        }));
        this.digitalVersion = false;
        this.bookingSearchTermSubject$ = new Subject();
        this.bookingSearchTermAction$ = this.bookingSearchTermSubject$.asObservable();
        this.bookingAutocompleteList$ = this.bookingSearchTermAction$.pipe(takeUntil(this.unsubscribe$), tap(() => { }), switchMap((searchTerm) => of(searchTerm).pipe(mergeMap((res) => {
            return this.globalSetupService.loadUsers({
                pageIndex: 0,
                showSearch: false,
                searchValue: res,
                pageSize: 10,
            }, this.currentZone);
        }))), tap((searchRes) => { }), catchError((err) => {
            return EMPTY;
        }));
        this.isShowableTrial = false;
        this.myControl = new FormControl("");
        this.timeZoneList = [];
        this.timeZoneListSearch = [];
        this.addLocationRoles = [];
        this.rolesSelectList = ["Super Admin", "Admin"];
        this.enableAllSites = false;
        this.locationName = "";
        this.mapURL = "";
        this.allSitesSubmitted = false;
        this.saveAllSites = () => {
            this.allSitesSubmitted = true;
            let userData = this.data["dialogData"]["editUsers"];
            let globalAdmin = this.addUsers.get("globalAdmin").value;
            if (this.addTeamsUserForm.valid || globalAdmin == true) {
                let locCount = 0;
                let rows = this.addTeamsUserForm.get("rows").value;
                let rolesToAssign = [];
                rows.forEach((resArray) => {
                    if (resArray["roles"].length == 0) {
                        resArray["roles"].push({
                            roles: 13,
                            roleName: "General User",
                        });
                    }
                    resArray["roles"].forEach((roles) => {
                        rolesToAssign.push({
                            roleId: roles["roles"],
                            zoneIds: [],
                        });
                    });
                    locCount = locCount + 1;
                });
                let uniqueRoles = [
                    ...new Map(rolesToAssign.map((item) => [item["roleId"], item])).values(),
                ];
                uniqueRoles.forEach((indRoles) => {
                    rows.forEach((locations) => {
                        locations["roles"].forEach((roles) => {
                            if (indRoles["roleId"] == roles["roles"]) {
                                indRoles["zoneIds"].push(locations["locationId"]);
                            }
                        });
                    });
                });
                let deptId = userData["deptId"];
                if (deptId == "") {
                    deptId = null;
                }
                else {
                    deptId = deptId;
                }
                if (globalAdmin == true) {
                    uniqueRoles = [];
                }
                else {
                    globalAdmin = false;
                }
                let obj;
                obj = {
                    allSiteAccess: false,
                    email: userData["email"],
                    firstName: userData["firstName"],
                    password: "",
                    lastName: userData["lastName"],
                    phoneNum: userData["phoneNum"],
                    rolesToAssign: uniqueRoles,
                    tenantId: 1,
                    empCode: userData["empId"],
                    deptId: deptId,
                    departName: userData["departName"],
                    grade: "MGR",
                    shiftStartTime: "0:0",
                    shiftEndTime: "23:59",
                    supervisorId: "",
                    autoAssign: this.autoAssignUsers,
                    globalAdmin: globalAdmin,
                };
                let users$;
                if (this.pageType == "editUsersTeams") {
                    obj["id"] = userData["userId"];
                    users$ = this.globalSetupService.updateGlobalSetupUsers(obj, this.digitalVersion);
                }
                else {
                    users$ = this.globalSetupService.addUserManually(obj);
                }
                this.loaderService
                    .showLoaderUntilCompleted(users$)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((res) => {
                    if (!!res["response"]) {
                        this.toaster.success(res["message"]);
                        this.proceedToPeopleTab();
                    }
                }, (err) => {
                    this.toaster.error(err.error.message);
                });
            }
        };
        this.disableLocationByIndex = null;
        this.selectedLocIndex = null;
        this.loadLocation = () => {
            let location = this.globalSetupService.loadLocations("ENABLE");
            this.loaderService.showLoaderUntilCompleted(location).subscribe((res) => {
                this.LOCATIONS_ELEMENT_DATA = res["response"]["content"];
                this.locationLength = this.LOCATIONS_ELEMENT_DATA.length;
                let addUserForm = this.addUsers.controls["locations"];
                if (this.pageType == "addUsersTeams") {
                    if (this.enableAllSites) {
                        this.LOCATIONS_ELEMENT_DATA.forEach((element, index) => {
                            this.onAddRow(); //Default atleast one row we are adding
                            this.addTeamsUserRows.controls[index]["controls"]["locations"].setValue(element.name);
                            this.addTeamsUserRows.controls[index]["controls"]["locationId"].setValue(element.zoneId);
                        });
                        this.addTeamsUserForm.updateValueAndValidity();
                    }
                }
                if (this.pageType == "editUsersTeams") {
                    const editUSers = this.data["dialogData"]["editUsers"];
                    let i = 0;
                    this.LOCATIONS_ELEMENT_DATA.forEach((element, index) => {
                        if (this.enableAllSites) {
                            this.onAddRow(); //For loading all locations
                            this.addTeamsUserRows.controls[index]["controls"]["locations"].setValue(element.name);
                            this.addTeamsUserRows.controls[index]["controls"]["locationId"].setValue(element.zoneId);
                        }
                        if (editUSers["userRolesMapDto"] != null &&
                            editUSers["userRolesMapDto"].length > 0) {
                            editUSers["userRolesMapDto"].forEach((res) => {
                                if (element.zoneId == res.zoneId) {
                                    if (!this.enableAllSites) {
                                        this.onAddRow();
                                        this.addTeamsUserRows.controls[i]["controls"]["locations"].setValue(element.name);
                                        this.addTeamsUserRows.controls[i]["controls"]["locationId"].setValue(element.zoneId);
                                    }
                                    res["rolesMap"].forEach((roleMap, roleIndex) => {
                                        if (roleMap["roleId"] != 13) {
                                            //Default general user role no need to show
                                            this.getDynamicRow["controls"][i]["controls"]["roles"]["controls"].push(new FormGroup({
                                                roles: new FormControl(roleMap["roleId"], Validators.required),
                                                roleName: new FormControl(roleMap["roleName"], Validators.required),
                                            }));
                                            this.getDynamicRow["controls"][i]["controls"]["roles"].updateValueAndValidity();
                                        }
                                    });
                                    i++;
                                }
                            });
                        }
                        //Default atleast one row we are adding
                        this.addTeamsUserRows.updateValueAndValidity();
                    });
                    this.addTeamsUserForm.updateValueAndValidity();
                }
                if (this.pageType == "editUsers") {
                    const editUSers = this.data["dialogData"]["editUsers"];
                    const intersection = this.LOCATIONS_ELEMENT_DATA.filter((element, index) => {
                        Object.keys(editUSers["rolesMap"]).filter((locId) => {
                            if (element["zoneId"] == locId) {
                                addUserForm.push(this.formBuilder.group({
                                    names: new FormControl(element),
                                    roles: new FormControl([], Validators.required),
                                    toggleControl: new FormControl(true),
                                }));
                                let roleForm = addUserForm["controls"][index].get("roles");
                                let rolesAssigned = [];
                                this.roleList.forEach((role) => {
                                    editUSers["roles"].filter((roleId) => {
                                        if (role["id"] ==
                                            roleId["id"]) {
                                            rolesAssigned.push(role);
                                        }
                                    });
                                });
                                roleForm.setValue(rolesAssigned);
                            }
                        });
                    });
                }
            }, (error) => { });
        };
        this.loadDepartment = () => {
            let department = this.globalSetupService.loadDepartments();
            this.loaderService.showLoaderUntilCompleted(department).subscribe((res) => {
                this.DEPARTMENT_ELEMENT_DATA = res["response"];
            }, (error) => { });
        };
        this.getRolesList = () => {
            this.globalSetupService.getAllRoles(this.currentZone).subscribe((res) => {
                this.roleList = res.response;
            }, (err) => {
                this.commonService.openSnackBar(err.error.message, "");
            });
        };
        this.nextPrevPage = (t) => {
            if (t == "n") {
                this.pageCount += 1;
            }
            else {
                this.pageCount -= 1;
            }
        };
        this.getEmployeeNameAutocompleteList = (searchTerm) => {
            this.nameSearchIsLoading = false;
            this.nameSearchTermSubject$.next(searchTerm.trim());
        };
        this.onselectNameData = (val) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.userDataOnSearch = val;
            if (val.phoneNum != null) {
            }
            //this.eName = val.firstName + " " + val.lastName;
            let lastName = val.lastName != null ? val.lastName : "";
            this.eName = val.firstName + " " + lastName;
            if (this.pageType == "addUserManually") {
                this.addUsers.controls.employeeName.setValue(val.firstName);
                this.addUsers.controls.employeeName.disable();
                this.addUsers.controls.lastName.setValue(val.lastName);
                this.addUsers.controls.lastName.disable();
                this.addUsers.controls.email.setValue(val.email);
                this.addUsers.controls.email.disable();
                // if (val.phoneNum != null) {
                //     this.addUsers.controls.phone.setValue(
                //         val.phoneNum.split("-")[1]
                //     );
                //     this.addUsers.controls.countryCode.setValue(
                //         val.phoneNum.split("-")[0]
                //     );
                //     this.countryCodeVal = val.phoneNum.split("-")[0];
                // }
            }
            else {
                this.assignNewGlobalForm.controls.fname.setValue(val.firstName);
                this.assignNewGlobalForm.controls.lname.setValue(val.lastName);
                this.assignNewGlobalForm.controls.email.setValue(val.email);
                // if (val.phoneNum != null) {
                //     this.assignNewGlobalForm.controls.phone.setValue(
                //         val.phoneNum.split("-")[1]
                //     );
                //     this.assignNewGlobalForm.controls.countryCode.setValue(
                //         val.phoneNum.split("-")[0]
                //     );
                //     this.countryCodeVal = val.phoneNum.split("-")[0];
                // }
            }
        });
        this.getUserAutocompleteList = (searchTerm) => {
            this.bookingSearchTermSubject$.next(searchTerm.trim());
        };
        this.locationAdd = (location) => {
            let data = { value: [], key: location, roleVal: "" };
            this.addLocationRoles.push(data);
            this.timeZoneListSearch = this.timeZoneList;
            this.addUsers.reset({ userSearch: "" });
        };
        this.deleteLocation = (locationVal) => {
            let removeLocation = this.addLocationRoles.filter((key) => key.key != locationVal);
            //delete this.addLocationRoles[locationVal];
            this.addLocationRoles = removeLocation;
        };
        this.addLocations = () => { };
        this.addRolesToLocation = (addRoleVal, index) => {
            this.searchControl.setValue("");
            Array.from(document.querySelectorAll("input")).forEach((el) => el.blur());
            let roles = this.getDynamicRow.value[index]["roles"].map((res) => {
                return res.roles;
            });
            this.locationName = "";
            if (!roles.includes(addRoleVal.id)) {
                this.getDynamicRow["controls"][index]["controls"]["roles"]["controls"].push(new FormGroup({
                    roles: new FormControl(addRoleVal.id, Validators.required),
                    roleName: new FormControl(addRoleVal.name, Validators.required),
                }));
                this.getDynamicRow["controls"][index]["controls"]["searchRoleName"].setValue("");
                this.getDynamicRow["controls"][index]["controls"]["roles"].updateValueAndValidity();
                //this.getDynamicRow['controls'][index]['roleName']['controls'].push(addRoleVal.name)
            }
            else {
                this.toaster.error(addRoleVal.name + this.translate.instant("roleAlreadyTagged"));
            }
        };
        this.removeLoctaionRoles = (index, rolesVal) => {
            let rolesList = this.addLocationRoles[index]["value"];
            rolesList = rolesList.filter((eleVal) => eleVal != rolesVal);
            this.addLocationRoles[index]["value"] = rolesList;
        };
        this.removeTeamLoctaionRoles = (parentIndex, roleIndex) => {
            this.getDynamicRow["controls"][parentIndex]["controls"]["roles"].removeAt(roleIndex);
            /*
        let rolesList = this.getDynamicRow[parentIndex]['value'];
        rolesList = rolesList.filter(eleVal=>eleVal != rolesVal);
        this.addLocationRoles[index]['value'] = rolesList;
        */
        };
        this.deleteTeamsLocation = (parentIndex) => {
            this.getDynamicRow.removeAt(parentIndex);
            /*
        let rolesList = this.getDynamicRow[parentIndex]['value'];
        rolesList = rolesList.filter(eleVal=>eleVal != rolesVal);
        this.addLocationRoles[index]['value'] = rolesList;
        */
        };
        this.addLocationTeams = (element) => {
            let locationsOnboarded = [];
            this.searchControl.reset("");
            document.getElementById("locationSelection").blur();
            this.searchLocation.setValue("");
            this.getDynamicRow.value.forEach((loc) => {
                locationsOnboarded.push(loc.locationId);
            });
            if (!locationsOnboarded.includes(element.zoneId)) {
                this.onAddRow();
                let index = this.addTeamsUserRows["controls"].length - 1;
                this.addTeamsUserRows["controls"][index]["controls"]["locations"].setValue(element.name);
                this.addTeamsUserRows["controls"][index]["controls"]["locationId"].setValue(element.zoneId);
                this.addTeamsUserRows["controls"][index]["controls"]["locationId"].setValidators(true);
                this.addTeamsUserForm.updateValueAndValidity();
            }
            else {
                this.toaster.error(this.translate.instant("locationAlreadyTagged"));
            }
        };
        this.totalPages = Math.ceil(this.roles.length / this.displayPerPage);
        let baseURL = environment.BASE_URL.split("/");
        this.clientName = localStorage.getItem("ssadmin_enterpriseName");
        //baseURL[2].split(".")[0];//tejarahul
        this.commonService.currentZone
            .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
            .subscribe((res) => {
            this.currentZone = res;
        });
        this.filteredCountry = this.countryCtrl.valueChanges.pipe(startWith(""), map((country) => country
            ? this.filterCountrys(country)
            : this.countryList.slice()));
        this.filteredStates1 = this.stateCtrl.valueChanges.pipe(startWith(""), map((state) => state ? this.filterStates1(state) : this.stateList.slice()));
        this.filteredCity = this.cityCtrl.valueChanges.pipe(startWith(""), map((city) => city ? this.filterCitys(city) : this.cityList.slice()));
        this.filteredTimezones = this.timeZoneCtrl.valueChanges.pipe(startWith(""), map((timezone) => timezone
            ? this.filterTimezone(timezone)
            : this.timeZones.slice()));
        // setTimeout(()=>{this.filteredRoles = this.searchControl.valueChanges.pipe(
        //     startWith(""),
        //     map((roles) =>
        //         roles ? this.filterRoles(roles) : this.roleList
        //     )
        // );},0);
        this.filteredRoles = this.searchControl.valueChanges.pipe(startWith(""), map((roles) => (roles ? this.filterRoles(roles) : this.roleList)));
        this.filteredRolesEdit = this.searchRoleNonTeams.valueChanges.pipe(startWith(""), map((roles) => (roles ? this.filterRoles(roles) : this.roleList)));
        this.filteredDepartments = this.departName.valueChanges.pipe(startWith(""), map((dept) => dept
            ? this.filterDepartmentNonTeams(dept)
            : this.DEPARTMENT_ELEMENT_DATA.slice()));
        this.filteredLocations = this.searchLocation.valueChanges.pipe(startWith(""), map((location) => location
            ? this.filterLocations(location)
            : this.LOCATIONS_ELEMENT_DATA.slice()));
        this.filteredLocationsNonTeams = this.searchLocation2NonTeams.valueChanges.pipe(startWith(""), map((location) => location
            ? this.filterLocations(location)
            : this.LOCATIONS_ELEMENT_DATA.slice()));
        this.filteredLocationsAdditionalNonTeams = this.searchLocationNonTeams.valueChanges.pipe(startWith(""), map((location) => location
            ? this.filterLocations(location)
            : this.LOCATIONS_ELEMENT_DATA.slice()));
        this.filteredCustomLocationsAdditionalNonTeams = this.searchLocationNonTeams.valueChanges.pipe(startWith(""), map((location) => location
            ? this.filterCustomLocationsNonTeams(location)
            : this.customLocationList.slice()));
    }
    filterCountrys(name) {
        let countryDataList = this.countryList.filter((country) => country.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
        if (countryDataList.length == 0) {
            this.addLocationsManuallyForm
                .get("country")
                .setErrors({ incorrect: true });
        }
        else {
            this.addLocationsManuallyForm.get("country").setErrors(null);
        }
        return countryDataList;
    }
    handleEmptyInput(eve) {
        if (eve.target.value == "") {
            this.assignNewGlobalForm.controls.fname.setValue("");
            this.assignNewGlobalForm.controls.lname.setValue("");
            this.assignNewGlobalForm.controls.email.setValue("");
            // this.assignNewGlobalForm.controls.phone.setValue("");
            // this.assignNewGlobalForm.controls.countryCode.setValue("");
        }
    }
    filterStates1(name) {
        let stateDataList = this.stateList.filter((state) => state.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
        if (stateDataList.length == 0) {
            this.addLocationsManuallyForm
                .get("state")
                .setErrors({ incorrect: true });
        }
        else {
            this.addLocationsManuallyForm.get("state").setErrors(null);
        }
        return stateDataList;
    }
    filterCitys(name) {
        let cityDataList = this.cityList.filter((city) => city.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
        if (cityDataList.length == 0) {
            this.addLocationsManuallyForm
                .get("city")
                .setErrors({ incorrect: true });
        }
        else {
            this.addLocationsManuallyForm.get("city").setErrors(null);
        }
        return cityDataList;
    }
    filterTimezone(name) {
        let timezoneDataList = this.roleList.filter((timezone) => timezone.name.toLowerCase().includes(name.toLowerCase()));
        if (timezoneDataList.length == 0) {
            this.addLocationsManuallyForm
                .get("timezone")
                .setErrors({ incorrect: true });
        }
        else {
            this.addLocationsManuallyForm.get("timezone").setErrors(null);
        }
        return timezoneDataList;
    }
    filterRoles(name) {
        this.rolesNonTeams = null;
        return this.roleList.filter((role) => role.name.toLowerCase().includes(name.toLowerCase()));
    }
    filterLocations(name) {
        this.locationNonTeams = null;
        let roleDataList = this.LOCATIONS_ELEMENT_DATA.filter((role) => role.name.toLowerCase().includes(name.toLowerCase()));
        return roleDataList;
    }
    filterCustomLocationsNonTeams(name) {
        this.locationNonTeams = null;
        let roleDataList = this.customLocationList.filter((role) => role.name.toLowerCase().includes(name.toLowerCase()));
        return roleDataList;
    }
    filterDepartmentNonTeams(name) {
        let roleDataList = this.DEPARTMENT_ELEMENT_DATA.filter((role) => role.name.toLowerCase().includes(name.toLowerCase()));
        return roleDataList;
    }
    // nameSearchTermSubject$ = new Subject<any>();
    // nameSearchTermAction$ = this.nameSearchTermSubject$.asObservable();
    // nameAutocompleteList$ = this.nameSearchTermAction$.pipe(
    //   tap(() => {
    //   }),
    //   switchMap((searchTerm) =>
    //     of(searchTerm).pipe(
    //       mergeMap((res) =>
    //         this.globalSetupService.getCountryName(this.currentZone)
    //       )
    //     )
    //   ),
    //   tap((searchRes) => {
    //     this.countryList=searchRes;
    //   }),
    //   catchError((err) => {
    //     return EMPTY;
    //   })
    // );
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log("this.data", this.data);
            this.getcountry();
            this.pageType = this.data["type"];
            try {
                // this.DEPARTMENT_ELEMENT_DATA = this.data["deptList"];
                this.LOCATIONS_ELEMENT_DATA = this.data["locationList"];
            }
            catch (error) {
                console.log(error);
            }
            this.digitalService.versionCheck.subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (res) {
                    this.digitalVersion = res;
                }
            }));
            this.digitalService.jumpreeProVersionCheck.subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (res) {
                    this.jumpreeProNonTeams = res;
                }
            }));
            if (this.pageType == "globalSetup") {
                this.isShowableTrial = this.data["isShowableTrial"];
            }
            if (this.pageType == "showAddedMembers") {
                this.globalUsers = this.data["dialogData"]["globalUsers"];
            }
            if (this.pageType == "editDepartmentManually") {
                this.editDeptData = Object.assign({}, this.data["dialogData"]);
                this.editDepartmentName = this.data["dialogData"]["deptData"]["name"];
                this.departmentForm = this.formBuilder.group({
                    departmentName: [this.editDepartmentName, Validators.required],
                });
                if (this.data["dialogData"]["individual"]) {
                    this.individual = true;
                }
            }
            if (this.pageType == "addUsersNew") {
                let department = this.globalSetupService.loadDepartments();
                let res = yield this.loaderService
                    .showLoaderUntilCompleted(department)
                    .toPromise();
                this.DEPARTMENT_ELEMENT_DATA = res["response"];
                this.accessAllLocationsNonTeams = this.data["manualAccess"];
            }
            this.addUsersNonTeams = this.formBuilder.group({
                employeeName: ["", Validators.required],
                lastName: [""],
                email: ["", [Validators.required, Validators.email]],
                employeeId: [""],
                departName: [""],
                globalAdmin: [false],
            });
            if (this.pageType == "editUsersNonTeams") {
                let department = this.globalSetupService.loadDepartments();
                this.loaderService.showLoaderUntilCompleted(department).subscribe((res) => {
                    this.DEPARTMENT_ELEMENT_DATA = res["response"];
                    this.accessAllLocationsNonTeams = this.data["dialogData"]["manualAccess"];
                    let userData = this.data["dialogData"]["editUsers"];
                    this.LOCATIONS_ELEMENT_DATA = this.data["dialogData"]["locationList"];
                    // this.DEPARTMENT_ELEMENT_DATA = this.data["dialogData"]["deptList"];
                    this.roleList = this.data["dialogData"]["roleList"];
                    if (this.data["dialogData"]["editUsers"]) {
                        this.addUsersNonTeams
                            .get("globalAdmin")
                            .setValue(userData["globalAdmin"]);
                        this.addUsersNonTeams
                            .get("employeeName")
                            .setValue(userData["firstName"]);
                        this.addUsersNonTeams
                            .get("lastName")
                            .setValue(userData["lastName"]);
                        this.addUsersNonTeams
                            .get("email")
                            .setValue(userData["email"]);
                        this.addUsersNonTeams
                            .get("employeeId")
                            .setValue(userData["empId"]);
                        let deptObj = this.DEPARTMENT_ELEMENT_DATA.find((item) => item.id === userData["deptId"]);
                        this.dept = deptObj;
                        this.departName.setValue(deptObj.name);
                        let locarray = Object.entries(userData["rolesMap"]);
                        if (locarray.length > 0) {
                            locarray.forEach((element) => {
                                let obj = this.LOCATIONS_ELEMENT_DATA.find((item) => item.zoneId == element[0]);
                                this.customLocationList.push(obj);
                                let roleValues = element[1];
                                let roless = [];
                                if (roleValues.length > 0) {
                                    roleValues.forEach((ele) => {
                                        let roleVal;
                                        roleVal = ele;
                                        if (roleVal != "GENERAL_USER" && roleVal != "APP USER") {
                                            let roleSelected = this.roleList.find((item) => item.name.toString() ==
                                                roleVal);
                                            let roleExtract = {
                                                id: roleSelected["id"],
                                                role: roleSelected["name"],
                                            };
                                            roless.push(roleExtract);
                                        }
                                    });
                                }
                                if (roless.length > 0) {
                                    let locObj = {
                                        name: obj["name"],
                                        zoneId: obj["zoneId"],
                                        roles: roless,
                                    };
                                    this.locationsListNonTeams.push(locObj);
                                }
                            });
                            if (this.locationsListNonTeams.length > 0) {
                                this.additionalRoles.setValue(true);
                                this.addLocNonTeams = true;
                            }
                        }
                    }
                }, (error) => { });
            }
            this.zones = moments.tz.names();
            this.timeZones = [];
            this.zones.forEach((element) => {
                var zoneObj = {};
                zoneObj["name"] =
                    element + " " + "(" + moments.tz(element).format("Z z") + ")";
                zoneObj["value"] = element;
                this.timeZones.push(zoneObj);
                //this.timeZones['name'] =  element + " " + "("+ moments.tz(element).format('Z z') + ")";
            });
            if (this.data["uploadType"] == "LOCATIONS") {
                this.titleForReupload = this.getTranslate("globalSetupLocations");
            }
            else if (this.data["uploadType"] == "DEPARTMENTS") {
                this.titleForReupload = this.getTranslate("globalSetupDepartment") + "s";
            }
            else if (this.data["uploadType"] == "USERS") {
                this.titleForReupload = this.getTranslate("globalSetupPeople");
            }
            if (this.pageType == "LocationIntro" ||
                this.pageType == "selectAddLocations") {
                if (this.data["dialogData"]["individual"]) {
                    this.individual = true;
                }
                // this.locationCountForIntroScreen = 0;
                this.locationCountForIntroScreen = this.data["dialogData"]["locationCountLength"];
            }
            else if (this.pageType == "onboardDepartmentStepTwo" ||
                this.pageType == "openDialogAddDepartment") {
                // this.departmentCountForIntroScreen = 0;
                if (this.data["dialogData"]["individual"]) {
                    this.individual = true;
                }
                this.departmentCountForIntroScreen = this.data["dialogData"]["departmentCountLength"];
                this.departmentForm = this.formBuilder.group({
                    departmentName: ["", Validators.required],
                });
            }
            else if (this.pageType == "onboardUsersStepThree" ||
                this.pageType == "addUsers") {
                if (this.data["individual"]) {
                    this.individual = true;
                }
                // this.userCountForIntroScreen = 0;
                this.userCountForIntroScreen = this.data["usersCountLength"];
            }
            this.clientForm = this.formBuilder.group({
                locationName: new FormControl("", Validators.compose([
                    Validators.maxLength(30),
                    Validators.minLength(3),
                    Validators.required,
                    Validators.pattern("^[a-zA-Z0-9 ]+$"),
                ])),
                searchAddress: ["", Validators.required],
                country: ["", Validators.required],
                state: ["", Validators.required],
                city: ["", Validators.required],
                timezone: ["", Validators.required],
            });
            this.addLocationsManuallyForm = this.formBuilder.group({
                locationName: ["", Validators.required],
                country: ["", Validators.required],
                state: ["", Validators.required],
                city: ["", Validators.required],
                timezone: ["", Validators.required],
            });
            this.addUsers = this.formBuilder.group({
                userSearch: ["", Validators.required],
                employeeName: ["", Validators.required],
                lastName: [""],
                email: ["", [Validators.required, Validators.email]],
                countryCode: [""],
                phone: [""],
                employeeId: [""],
                groups: [""],
                globalAdmin: [false],
                locations: this.formBuilder.array([], [Validators.required, Validators.minLength(1)]),
            });
            this.selectLocationForm = this.formBuilder.group({
                country: ["", Validators.required],
                state: ["", Validators.required],
                city: ["", Validators.required],
                location: ["", Validators.required],
                locationName: ["", Validators.required],
            });
            this.assignNewGlobalForm = this.formBuilder.group({
                userSearch: ["", Validators.required],
                fname: [""],
                lname: [""],
                email: [""],
            });
            this.assignNewGlobalForm.controls.fname.disable();
            this.assignNewGlobalForm.controls.lname.disable();
            this.assignNewGlobalForm.controls.email.disable();
            // this.assignNewGlobalForm.controls.phone.disable();
            // this.assignNewGlobalForm.controls.countryCode.disable();
            if (this.pageType == "editLocationsManually") {
                this.locationData = this.data["dialogData"]["locationData"];
                this.addLocationsManuallyForm.controls["locationName"].setValue(this.locationData["name"]);
                this.clientForm.controls["locationName"].setValue(this.locationData["name"]);
                this.countryCtrl.setValue(this.locationData["country"]["name"]);
                this.stateCtrl.setValue(this.locationData["state"]["name"]);
                this.cityCtrl.setValue(this.locationData["city"]["name"]);
                this.addLocationsManuallyForm.controls["timezone"].setValue(this.locationData["timezone"]);
                this.countryCtrl.setErrors(null);
                this.stateCtrl.setErrors(null);
                this.cityCtrl.setErrors(null);
                this.addLocationsManuallyForm.get("timezone").setErrors(null);
                this.readOnly = true;
                this.individual = this.data["dialogData"]["individual"];
            }
            if (this.pageType == "globalSetup") {
                this.userName = localStorage.getItem("ssadmin_name");
            }
            this.pageType = this.data["type"];
            if (this.pageType == "incorrectEntries") {
                this.fileType = this.data["fileType"];
                this.templateData = this.data["templateData"];
                this.correctData = this.data["correctData"];
                this.incorrectData = this.data["incorrectData"];
                this.fileToUpload = this.data["fileUploaded"];
                if (this.fileType == "DEPARTMENTS") {
                    this.displayedColumnsIncorrectData = [
                        "slNo",
                        "departmentName",
                        "reason",
                    ];
                }
                if (this.fileType == "USERS") {
                    this.displayedColumnsIncorrectData = [
                        "slNo",
                        "departmentName",
                        "reason",
                    ];
                }
                if (this.fileType == "LOCATIONS") {
                    this.displayedColumnsIncorrectData = [
                        "slNo",
                        "locationName",
                        "country",
                        "state",
                        "city",
                        "timeZone",
                        "reason",
                    ];
                }
            }
            else if (this.pageType == "uploadCsvWithFixes") {
                this.templateData = this.data["templateData"];
                this.incorrectData = this.data["incorrectData"];
                this.fileToUpload = this.data["fileUploaded"];
            }
            if (this.pageType == "editGlobalUser") {
                let globalUSer = this.data["dialogData"]["userData"];
                this.assignNewGlobalForm.get("fname").setValue(globalUSer["fname"]);
                this.assignNewGlobalForm.get("lname").setValue(globalUSer["lname"]);
                this.assignNewGlobalForm.get("email").setValue(globalUSer["email"]);
                //this.assignNewGlobalForm.get("phone").setValue(globalUSer["phoneNo"].split("-")[1]);
                //this.assignNewGlobalForm.get("countryCode").setValue(globalUSer["phoneNo"].split("-")[0]);
            }
            if (this.pageType == "showAssignNewGlobal" ||
                this.pageType == "editGlobalUser") {
                //this.getCountryCodes();
            }
            if (this.pageType == "showSecondaryDepartments") {
                this.nissanSecondaryDeptData = this.data["dialogData"];
                console.log(this.nissanSecondaryDeptData.userData);
            }
            if (this.pageType == "addUsersTeams" ||
                this.pageType == "editUsersTeams") {
                this.addTeamsUserRows = this.formBuilder.array([]);
                this.addTeamsUserForm = this.formBuilder.group({
                    rows: this.addTeamsUserRows,
                });
                this.LOCATIONS_ELEMENT_DATA = this.data["dialogData"]["locationList"];
                this.roleList = this.data["dialogData"]["roleList"];
                this.loadLocation();
                this.enableAllSites = this.data["dialogData"]["enableAllSites"];
                this.individual = this.data["dialogData"]["individual"];
                const editUSers = this.data["dialogData"]["editUsers"];
                if (this.pageType == "editUsersTeams") {
                    this.addUsers
                        .get("globalAdmin")
                        .setValue(editUSers["globalAdmin"]);
                }
                let lastName = "";
                if (editUSers["lastName"] != null) {
                    lastName = editUSers["lastName"];
                }
                let employeeName = editUSers["firstName"] + " " + lastName;
                this.addUsers.controls["employeeName"].setValue(employeeName);
                if (!this.enableAllSites) {
                    this.addTeamsUserForm.controls["rows"].setValidators(Validators.required);
                }
            }
            if (this.pageType == "editUsers") {
                this.loadLocation();
                this.loadDepartment();
                this.getRolesList();
                this.getCountryCodes();
                this.enableAllSites = this.dialog["dialogData"]["enableAllSites"];
                const editUSers = this.data["dialogData"]["editUsers"];
                let employeeName = editUSers["firstName"];
                let lastName = editUSers["lastName"];
                let email = editUSers["email"];
                let phoneNum = editUSers["phoneNum"].split("-")[1];
                let cCode = editUSers["phoneNum"].split("-")[0];
                let empId = editUSers["empId"];
                let deptId = editUSers["deptId"];
                let globalAdmin = editUSers["globalAdmin"];
                this.addUsers.controls["employeeName"].setValue(employeeName);
                this.addUsers.controls["lastName"].setValue(lastName);
                this.addUsers.controls["email"].setValue(email);
                this.addUsers.controls["countryCode"].setValue(cCode);
                this.addUsers.controls["phone"].setValue(phoneNum);
                this.addUsers.controls["employeeId"].setValue(empId);
                this.addUsers.controls["email"].setValue(email);
                this.addUsers.controls["groups"].setValue(deptId);
                this.addUsers.controls["globalAdmin"].setValue(globalAdmin);
                /*
          this.formBuilder.group({
            employeeName: ['', Validators.required],
            email: ['', [Validators.required,Validators.email]],
            phone: ['', Validators.required],
            employeeId: ['', Validators.required],
            groups: [''],
            locations: this.formBuilder.array([],[Validators.required, Validators.minLength(1)])
          });
          */
                this.individual = this.data["dialogData"]["individual"];
            }
            if (this.pageType == "disableUser") {
                console.log("hello");
                console.log(this.data);
                this.disableUserForm = this.formBuilder.group({
                    disableUsers: ['', null]
                });
            }
            // this.timeZoneListForAddEdit = this.myControl.valueChanges.pipe(
            //   startWith(''),
            //   map(value => this._timeZoneFilter(value || '')),
            // );
            if (this.pageType == 'confirmForSettingTenant') {
                this.tenantSettingsFalseToTrue = this.data['falseToTrue'];
            }
        });
    }
    timeZoneFilter(value) {
        const filterValue = value.toLowerCase();
        this.timeZoneListSearch = this.LOCATIONS_ELEMENT_DATA.filter((option) => option["name"].toLowerCase().includes(filterValue));
    }
    createItemFormGroup() {
        return this.formBuilder.group({
            locations: new FormControl("", Validators.required),
            locationId: new FormControl("", Validators.required),
            roles: this.formBuilder.array([]),
            searchRoleName: new FormControl(""),
        });
        // return this.formBuilder.group({
        //   locations: new FormControl("",Validators.required),
        //   roles: this.formBuilder.array([],Validators.required),
        //   roleName: this.formBuilder.array([],Validators.required)
        // });
    }
    onAddRow() {
        this.addTeamsUserRows.push(this.createItemFormGroup());
    }
    get getDynamicRow() {
        return this.addTeamsUserForm.get("rows");
    }
    getCountryCodes() {
        let currentContext = this;
        const countryCodes = this.globalSetupService.getCountryCodesGlobalAdmin(22, currentContext.currentZone);
        this.loaderService.showLoaderUntilCompleted(countryCodes).subscribe((res) => {
            currentContext.countryCodeList = res.response;
        }, (err) => {
            this.commonService.openSnackBar(err.error.message, "");
        });
    }
    loadLocations() {
        this.apiService.api.then((maps) => {
            this.showInfo = false;
            this.clientForm.get("searchAddress").setValue("");
            document.getElementById("mapped").style.height = "0px";
            this.initAutocomplete(maps);
            this.initMap(maps);
        });
    }
    initAutocomplete(maps) {
        let autocomplete = new maps.places.Autocomplete(this.searchElementRef.nativeElement);
        autocomplete.addListener("place_changed", () => {
            this.ngZone.run(() => {
                this.onPlaceChange(autocomplete.getPlace());
            });
        });
    }
    loaderShow() {
        if (this.clientForm.get("searchAddress").value.length > 0) {
            this.nameSearchIsLoading = true;
        }
        if (this.clientForm.get("searchAddress").value.length == 0) {
            this.nameSearchIsLoading = false;
        }
    }
    initMap(maps) {
        this.map = new maps.Map(this.mapElementRef.nativeElement, {
            zoom: 7,
        });
    }
    onPlaceChange(place) {
        this.showInfo = true;
        const myLatLng = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
        };
        this.map.setCenter(myLatLng);
        let mapped = new google.maps.Map(document.getElementById("mapped"), {
            zoom: 18,
            center: myLatLng,
        });
        document.getElementById("mapped").style.height = "300px";
        new google.maps.Marker({
            position: myLatLng,
            map: mapped,
        });
        const location = this.locationFromPlace(place);
        this.entries = [
            {
                place,
                location,
            },
        ];
        let locations = this.entries[0]["location"];
        let places = this.entries[0]["place"];
        this.mapURL = places.url;
        let loadMapLocation = this.globalSetupService.getGoogleMap(this.currentZone, this.entries[0]);
        this.loaderService.showLoaderUntilCompleted(loadMapLocation).subscribe((res) => {
            this.x = res;
            this.clientForm.controls["state"].setValue(this.x["state"]["name"]);
            this.clientForm.controls["country"].setValue(this.x["country"]["name"]);
            this.clientForm.controls["city"].setValue(this.x["name"]);
            this.clientForm.controls["timezone"].setValue(this.x["timezone"]);
            this.nameSearchIsLoading = false;
        }, (error) => {
            this.nameSearchIsLoading = false;
        }, () => {
            this.nameSearchIsLoading = false;
        });
        // let country = locations['countryName'];
        // let city = locations['cityName'];
        //this.clientForm.controls['city'].setValue(city);
    }
    getComponent(components, name) {
        return components.filter((component) => component.types[0] === name)[0];
    }
    getLong(components, name) {
        const component = this.getComponent(components, name);
        return component && component.long_name;
    }
    getShort(components, name) {
        const component = this.getComponent(components, name);
        return component && component.short_name;
    }
    locationFromPlace(place) {
        const components = place.address_components;
        if (components === undefined) {
            return null;
        }
        const areaLevel3 = this.getShort(components, "administrative_area_level_3");
        const locality = this.getLong(components, "locality");
        const cityName = locality || areaLevel3;
        const countryName = this.getLong(components, "country");
        const countryCode = this.getShort(components, "country");
        const stateCode = this.getShort(components, "administrative_area_level_1");
        const name = place.name !== cityName ? place.name : null;
        const coordinates = {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
        };
        const bounds = place.geometry.viewport.toJSON();
        // placeId is in place.place_id, if needed
        return {
            name,
            cityName,
            countryName,
            countryCode,
            stateCode,
            bounds,
            coordinates,
        };
    }
    getcountry() {
        let location = this.globalSetupService.getCountryName(this.currentZone);
        this.loaderService.showLoaderUntilCompleted(location).subscribe((res) => {
            this.countryList = res;
        }, (error) => { });
    }
    deleteGlobalUser(gb) {
        let globalUser = this.globalSetupService.deleteGlobalUser(gb.userId);
        this.loaderService.showLoaderUntilCompleted(globalUser).subscribe((res) => {
            this.countryList = res;
            let x = this.globalUsers.filter((res) => {
                return res["userId"] != gb.userId;
            });
            this.globalUsers = x;
        }, (error) => { });
    }
    closeGlobalPopup() {
        this.closePopup("globalUser");
    }
    countrySelection(countryData) {
        this.selectedCountry = countryData;
        if (this.selectedCountry != "null") {
            this.selectedState = null;
            this.selectedCity = null;
            this.stateList = [];
            this.cityList = [];
            let filterCountryId = this.countryList.find((m) => m.name === this.selectedCountry);
            let childLevel = "STATE";
            let data = { zoneIds: [filterCountryId.id], childLevel };
            this.countryId = filterCountryId.id;
            const stateLoad$ = this.globalSetupService.childZones(this.currentZone, data);
            this.loaderService
                .showLoaderUntilCompleted(stateLoad$)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res) => {
                this.stateList =
                    res["response"].zoneMap[filterCountryId.id];
                this.isReadonlyState = false;
                this.isReadonlyCity = true;
                this.stateCtrl.reset();
                this.cityCtrl.reset();
            });
        }
        else {
            this.selectedState = null;
            this.selectedCity = null;
            this.stateList = [];
            this.cityList = [];
        }
    }
    stateSelection(stateData) {
        this.selectedState = stateData;
        if (this.selectedState != "null") {
            let filterStateId = this.stateList.find((m) => m.name === this.selectedState);
            let childLevel = "CITY";
            if (filterStateId != undefined) {
                this.parentzoneId = filterStateId.id;
                let data = { zoneIds: [filterStateId.id], childLevel };
                this.stateId = filterStateId.id;
                const cityLoad$ = this.globalSetupService.childZones(this.currentZone, data);
                this.loaderService
                    .showLoaderUntilCompleted(cityLoad$)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((res) => {
                    this.cityList =
                        res["response"].zoneMap[filterStateId.id];
                    this.cityCtrl.reset();
                    this.isReadonlyCity = false;
                });
            }
        }
        else {
            this.selectedCity = null;
            this.cityList = [];
        }
    }
    citySelection(cityData) {
        this.selectedCity = cityData;
        if (this.selectedCountry != null &&
            this.selectedCountry != "null" &&
            this.selectedState != null &&
            this.selectedState != "null") {
            if (this.selectedCity != null &&
                this.selectedCity != undefined &&
                this.selectedCity != "null") {
                {
                    let filterCityId = this.cityList.find((m) => m.name === this.selectedCity);
                    this.cityId = filterCityId.id;
                }
            }
        }
        else {
            this.selectedCity = "null";
        }
    }
    addMoreLocationManully() {
        this.submitted = true;
        if (this.addLocationsManuallyForm.invalid) {
            return;
        }
        else {
            let reqObj = {
                cityId: this.cityId,
                countryId: this.countryId,
                name: this.addLocationsManuallyForm.get("locationName").value,
                parentZoneId: this.cityId,
                stateId: this.stateId,
                status: "ENABLED",
                type: "SITE",
                timezone: this.addLocationsManuallyForm.get("timezone").value,
            };
            let location = this.globalSetupService.createLocation(this.currentZone, reqObj);
            this.loaderService.showLoaderUntilCompleted(location).subscribe((res) => {
                if (res) {
                    this.countLocation = this.countLocation + 1;
                    this.addLocationsManually();
                    this.addLocationsManuallyForm.reset();
                    this.countryCtrl.reset();
                    this.cityCtrl.reset();
                    this.stateCtrl.reset();
                    this.timeZoneCtrl.reset();
                    this.submitted = false;
                    this.isReadonlyCity = true;
                    this.isReadonlyState = true;
                    this.toaster.success(this.translate.instant("locationAddedSuccessfully"));
                }
            }, (err) => {
                this.toaster.error(err.error.message);
            });
            // this.redirectLocation();
        }
    }
    addMoreLocation() {
        this.submitted = true;
        if (this.clientForm.invalid) {
            return;
        }
        else {
            let reqObj = {
                cityId: this.x["zoneId"],
                countryId: this.x["country"]["id"],
                name: this.clientForm.get("locationName").value,
                parentZoneId: this.x["zoneId"],
                stateId: this.x["state"]["id"],
                status: "ENABLED",
                type: "SITE",
                timezone: this.clientForm.get("timezone").value,
            };
            let location = this.globalSetupService.createLocation(this.currentZone, reqObj);
            this.loaderService.showLoaderUntilCompleted(location).subscribe((res) => {
                if (res) {
                    this.countLocation = this.countLocation + 1;
                    this.addLocationOptions();
                    this.clientForm.reset();
                    this.submitted = false;
                    this.toaster.success(this.translate.instant("locationAddedSuccessfully"));
                }
            }, (err) => {
                this.toaster.error(err.error.message);
            });
            // this.redirectLocation();
        }
    }
    addMoreDepartments() {
        if (this.departmentForm.value.departmentName != null &&
            this.departmentForm.value.departmentName != "") {
            this.departmentValueName = this.departmentForm.value.departmentName;
            this.postDepartment("addMoreDepartment");
        }
    }
    removeDepartment(val) {
        const index = this.departmentList.findIndex((item) => item == val);
        if (index > -1) {
            // only splice array when item is found
            this.departmentList.splice(index, 1); // 2nd parameter means remove one item only
        }
    }
    removeUserLocation(val) {
        const index = val;
        if (index > -1) {
            // only splice array when item is found
            this.selectedLocations.splice(index, 1); // 2nd parameter means remove one item only
            this.selectedloc = new FormControl(this.selectedLocations);
        }
    }
    removeUserRoles(val) {
        const index = val;
        if (index > -1) {
            // only splice array when item is found
            this.selectedRoles.splice(index, 1); // 2nd parameter means remove one item only
            this.selectedRol = new FormControl(this.selectedRoles);
        }
    }
    submitDepartment() {
        this.submittedDepartment = true;
        if (this.departmentList.length == 0) {
            if (this.departmentForm.invalid) {
                return;
            }
            else {
                this.departmentValueName = this.departmentForm.value.departmentName;
                if (this.pageType == "addDepartmentManually") {
                    this.postDepartment("submitDepartment");
                }
                else if (this.pageType == "editDepartmentManually") {
                    this.updateDepartment();
                }
            }
        }
        else {
            this.redirectLocation();
        }
    }
    postDepartment(type) {
        let reqObj = {
            name: this.departmentValueName,
            status: true,
            tenantId: 1,
            enterpriseId: 1,
            createdAt: 1667465091028,
            createdBy: 1,
            modifiedAt: 1,
            modifiedBy: 1,
            color: null,
        };
        let location = this.globalSetupService.createDepartment(this.currentZone, reqObj);
        this.loaderService.showLoaderUntilCompleted(location).subscribe((result) => {
            let res = result["response"];
            if (res) {
                if (!this.departmentList.includes(res.name)) {
                    this.departmentList.push(res.name);
                }
                this.toaster.success(result["message"]);
                if (type != "addMoreDepartment" && !!res.name) {
                    this.redirectLocation();
                }
                if (type == "addMoreDepartment") {
                    this.departmentForm.controls["departmentName"].setErrors(null);
                }
            }
        }, (err) => {
            this.toaster.error(err.error.message);
        });
    }
    updateDepartment() {
        let reqObj = {
            id: this.editDeptData["deptData"]["id"],
            name: this.departmentValueName,
            status: this.editDeptData["deptData"]["status"],
            tenantId: 1,
            enterpriseId: 1,
            createdAt: 1667465091028,
            createdBy: 1,
            modifiedAt: 1,
            modifiedBy: 1,
            color: null,
        };
        let location = this.globalSetupService.updateDepartment(this.currentZone, reqObj);
        this.loaderService.showLoaderUntilCompleted(location).subscribe((res) => {
            if (res) {
                if (!this.departmentList.includes(this.departmentForm.value.departmentName)) {
                    this.departmentList.push(this.departmentForm.value.departmentName);
                }
                if (this.pageType == "editDepartmentManually") {
                    this.redirectLocation();
                }
                if (!!res && res["message"]) {
                    this.toaster.success(res["message"]);
                }
            }
        }, (err) => {
            this.toaster.error(err.error.message);
        });
    }
    submitAddLocation() {
        this.submitted = true;
        if (this.countLocation > 0 && this.clientForm.invalid) {
            this.redirectLocation();
        }
        else if (this.countLocation == 0 ||
            (this.countLocation > 0 && this.clientForm.valid)) {
            if (this.clientForm.invalid) {
                return;
            }
            else {
                let reqObj = {
                    cityId: this.x["zoneId"],
                    countryId: this.x["country"]["id"],
                    name: this.clientForm.get("locationName").value,
                    parentZoneId: this.x["zoneId"],
                    stateId: this.x["state"]["id"],
                    status: "ENABLED",
                    type: "SITE",
                    timezone: this.clientForm.get("timezone").value,
                };
                let location = this.globalSetupService.createLocation(this.currentZone, reqObj);
                this.loaderService.showLoaderUntilCompleted(location).subscribe((res) => {
                    if (res) {
                        this.redirectLocation();
                    }
                }, (error) => { });
                // this.redirectLocation();
            }
        }
        else {
            this.redirectLocation();
        }
    }
    submitEditLocationOptions() {
        this.submitted = true;
        if (this.clientForm.invalid) {
            return;
        }
        let reqObj = {
            id: this.locationData["zoneId"],
            name: this.clientForm.get("locationName").value,
            timezone: this.clientForm.get("timezone").value,
        };
        let location = this.globalSetupService.updateLocation(this.currentZone, reqObj);
        this.loaderService.showLoaderUntilCompleted(location).subscribe((res) => {
            if (res) {
                this.redirectLocation();
                this.toaster.success(this.translate.instant("msg_location_updated"));
            }
        }, (err) => {
            this.toaster.error(err.error.message);
        });
    }
    submiteditLocationsManuallyForm() {
        this.submitted = true;
        if (this.addLocationsManuallyForm.get("locationName").invalid) {
            return;
        }
        let reqObj = {
            id: this.locationData["zoneId"],
            name: this.addLocationsManuallyForm.get("locationName").value,
            timezone: this.addLocationsManuallyForm.get("timezone").value,
        };
        let location = this.globalSetupService.updateLocation(this.currentZone, reqObj);
        this.loaderService.showLoaderUntilCompleted(location).subscribe((res) => {
            if (res) {
                this.toaster.success(this.translate.instant("msg_location_updated"));
                this.redirectLocation();
            }
        }, (err) => {
            this.toaster.error(err.error.message);
        });
        // this.redirectLocation();
    }
    submitaddLocationsManuallyForm() {
        this.submitted = true;
        if (this.countLocation == 0 ||
            (this.countLocation > 1 && this.addLocationsManuallyForm.valid)) {
            if (this.addLocationsManuallyForm.invalid) {
                return;
            }
            else {
                let reqObj = {
                    cityId: this.cityId,
                    countryId: this.countryId,
                    name: this.addLocationsManuallyForm.get("locationName")
                        .value,
                    parentZoneId: this.cityId,
                    stateId: this.stateId,
                    status: "ENABLED",
                    type: "SITE",
                    timezone: this.addLocationsManuallyForm.get("timezone")
                        .value,
                };
                let location = this.globalSetupService.createLocation(this.currentZone, reqObj);
                this.loaderService.showLoaderUntilCompleted(location).subscribe((res) => {
                    if (res) {
                        this.toaster.success(this.translate.instant("locationAddedSuccessfully"));
                        this.redirectLocation();
                    }
                }, (err) => {
                    this.toaster.error(err.error.message);
                });
            }
        }
        else {
            this.toaster.success(this.translate.instant("locationAddedSuccessfully"));
            this.redirectLocation();
        }
    }
    submitselectLocationForm() {
        this.submitted = true;
        if (this.selectLocationForm.invalid) {
            return;
        }
        else {
            this.redirectLocation();
        }
    }
    removeUploadedFile() {
        this.submitted = false;
        this.uploadedFileName = "";
        this.fileToUpload = null;
        this.labelImport.nativeElement.innerText = "";
        this.fileUploader.nativeElement.value = null;
    }
    submitassignNewGlobalForm() {
        this.submitted = true;
        if (this.assignNewGlobalForm.invalid ||
            typeof this.userDataOnSearch == "undefined" ||
            this.userDataOnSearch["email"] == "") {
            this.toaster.error(this.translate.instant("pleaseSelectUser"));
            return;
        }
        else {
            let data = {
                allSiteAccess: true,
                email: this.assignNewGlobalForm.get("email").value,
                firstName: this.assignNewGlobalForm.get("fname").value,
                lastName: this.assignNewGlobalForm.get("lname").value,
                // phoneNum:
                //     this.assignNewGlobalForm.get("countryCode").value +
                //     "-" +
                //     this.assignNewGlobalForm.get("phone").value,
                rolesToAssign: [],
                tenantId: 1,
                autoAssign: this.autoAssignUsers,
            };
            let url = "";
            if (this.pageType == "editGlobalUser") {
                data["id"] = this.data["dialogData"]["userData"]["userId"];
                url = this.globalSetupService.onBoardEditGlobalAdmin(data);
            }
            else {
                url = this.globalSetupService.onBoardGlobalAdmin(data);
            }
            this.loaderService
                .showLoaderUntilCompleted(url)
                .subscribe((res) => {
                if (this.pageType != "editGlobalUser") {
                    this.userId = res["response"]["userId"];
                    this.addglobalAdmin(res["response"]["userId"]);
                }
                else {
                    this.closePopup(this.pageType);
                }
            });
        }
    }
    addglobalAdmin(userId) {
        this.globalSetupService
            .onBoardUserGlobalAdmin(userId)
            .subscribe((res) => {
            this.sendEmailPopup();
        });
    }
    closePopup(pageType) {
        let dataOnclosePopup = {};
        if (this.pageType == "addLocationsManually" ||
            this.pageType == "addLocationsOptions") {
            dataOnclosePopup.locationCount = this.locationCountForIntroScreen;
        }
        else if (this.pageType == "addDepartmentManually") {
            dataOnclosePopup.departmentCount = this.departmentCountForIntroScreen;
        }
        dataOnclosePopup.pageType = pageType;
        this.dialogRef.close(dataOnclosePopup);
    }
    uploadCorrectedEntries() { }
    CloseAlert() {
        this.allSiteAccessUsers = false;
        this.autoAssignUsers = false;
        let data = {
            pageType: "alert",
        };
        this.dialogRef.close(data);
    }
    closePopupInMiddle(pageType) {
        if (pageType == "LocationIntro" ||
            pageType == "selectAddLocations" ||
            pageType == "addLocationsManually" ||
            pageType == "addLocationsManuallyNonTeams" ||
            pageType == "addLocationsOptions") {
            this.globalSetupTypeForAlertPopup = "Locations";
        }
        if (pageType == "onboardDepartmentStepTwo" ||
            pageType == "openDialogAddDepartment" ||
            pageType == "addDepartmentManually") {
            this.globalSetupTypeForAlertPopup = "Departments";
        }
        if (pageType == "onboardUsersStepThree" ||
            pageType == "accessToAllUsersLocations" ||
            pageType == "generalUserRole" ||
            pageType == "addUsers") {
            this.globalSetupTypeForAlertPopup = "People";
        }
        if (pageType == "onboardSettingsStepFour" ||
            pageType == "showSettingsOnboard") {
            this.globalSetupTypeForAlertPopup = "Settings";
        }
        this.previousPage = pageType;
        if (!this.individual) {
            this.pageType = "ShowAlert";
        }
        else {
            let loadUserOnClosePopup;
            if (pageType == "addUserManually") {
                if (!this.digitalVersion) {
                    this.addUsers.controls["userSearch"].setValidators(null);
                }
                loadUserOnClosePopup = "yes";
            }
            this.closeConfirmPopup(loadUserOnClosePopup);
        }
    }
    closeReUploadUsersPopup() {
        let templateData = {
            closePopup: true,
            templateData: this.templateData,
            incorrectData: this.incorrectData,
            uploadedFile: this.fileToUpload,
            uploadType: this.data["uploadType"],
        };
        this.dialogRef.close(templateData);
    }
    backBtn() {
        if (this.pageType == "selectAddLocations") {
            this.pageType = "LocationIntro";
        }
        else if (this.pageType == "addLocationsOptions") {
            this.pageType = "selectAddLocations";
        }
        else if (this.pageType == "addLocationsManually") {
            this.pageType = "addLocationsOptions";
        }
        else if (this.pageType == "selectLocation") {
            this.pageType = "addLocationsOptions";
        }
        else if (this.pageType == "sendEmailPopup") {
            this.pageType = "showAssignNewGlobal";
        }
    }
    backBtnDepartment() {
        if (this.pageType == "openDialogAddDepartment") {
            this.pageType = "onboardDepartmentStepTwo";
        }
        else if (this.pageType == "addDepartmentManually" ||
            this.pageType == "editDepartmentManually") {
            this.pageType = "openDialogAddDepartment";
        }
    }
    backBtnUsers() {
        if (this.pageType == "accessToAllUsersLocations") {
            this.pageType = "onboardUsersStepThree";
        }
        else if (this.pageType == "generalUserRole") {
            this.pageType = "accessToAllUsersLocations";
        }
        else if (this.pageType == "addUsers") {
            this.pageType = "generalUserRole";
        }
        else if (this.pageType == "tagLocations" ||
            this.pageType == "showRoles") {
            this.pageType = "addUserManually";
        }
        else if (this.pageType == "addUserManually") {
            this.pageType = "addUsers";
        }
        else if (this.pageType == "ShowAlertPopup") {
            this.pageType = "addUserManually";
        }
        else if (this.pageType == "onboardUsersStepThree") {
            this.pageType = "onboardDepartmentStepTwo";
        }
        if (this.pageType == "addUserManuallyNonTeams") {
            this.pageType = "addUsersNew";
        }
        if (this.pageType == "addUsersNew") {
            if (this.addViaExcelNew) {
                this.addViaExcelNew = false;
            }
        }
        if (this.previousUserPageType == "addUserManually") {
            this.pageType = "addUserManually";
        }
        else if (this.previousUserPageType == "editUsers") {
            this.pageType = "editUsers";
        }
    }
    backBtnSettings() {
        if (this.pageType == "showSettingsOnboard") {
            this.pageType = "onboardSettingsStepFour";
        }
    }
    showAlertPopup(previousPageType) {
        if (this.digitalVersion) {
            this.saveAllSites();
            // if(this.enableAllSites){
            //   this.saveAllSites();
            // }else{
            //   this.saveAllSites();
            // }
        }
        else {
            this.previousUserPageType = previousPageType;
            this.submittedUsers = true;
            if (this.addUsers.get("globalAdmin").value) {
                this.addUsers.get("locations").setValue([]);
                this.addUsers.get("locations").setValidators(null);
                this.addUsers.get("locations").updateValueAndValidity();
            }
            if (this.addUsers.controls.locations.errors != null) {
                this.pageType = "ShowAlertPopup";
                return false;
            }
            let locCount = 0;
            let loc = this.addUsers.get("locations").value;
            let rolesToAssign = [];
            loc.forEach((locations) => {
                if (locations.toggleControl) {
                    locations["roles"].forEach((roles) => {
                        rolesToAssign.push({
                            roleId: roles.id,
                            zoneIds: [],
                        });
                    });
                    locCount = locCount + 1;
                }
            });
            const uniqueRoles = [
                ...new Map(rolesToAssign.map((item) => [item["roleId"], item])).values(),
            ];
            uniqueRoles.forEach((indRoles) => {
                loc.forEach((locations) => {
                    if (locations.toggleControl) {
                        locations["roles"].forEach((roles) => {
                            if (indRoles["roleId"] == roles.id) {
                                indRoles["zoneIds"].push(locations["names"]["zoneId"]);
                            }
                        });
                    }
                });
            });
            let deptId = this.addUsers.get("groups").value;
            if (deptId == "") {
                deptId = null;
            }
            else {
                deptId = deptId;
            }
            let globalAdmin = this.addUsers.get("globalAdmin").value;
            let obj;
            obj = {
                allSiteAccess: this.allSiteAccessUsers,
                email: this.addUsers.get("email").value,
                firstName: this.addUsers.get("employeeName").value,
                password: "",
                lastName: this.addUsers.get("lastName").value,
                phoneNum: this.addUsers.get("countryCode").value +
                    "-" +
                    this.addUsers.get("phone").value,
                rolesToAssign: uniqueRoles,
                tenantId: 1,
                empCode: this.addUsers.get("employeeId").value,
                deptId: deptId,
                departName: this.addUsers.get("departName").value,
                grade: "MGR",
                shiftStartTime: "0:0",
                shiftEndTime: "23:59",
                supervisorId: "",
                autoAssign: this.autoAssignUsers,
                globalAdmin: globalAdmin,
            };
            if (this.pageType == "addUserManually") {
                obj = Object.assign({}, obj);
            }
            else if (this.pageType == "editUsers") {
                obj = Object.assign({ id: this.data["dialogData"]["editUsers"]["userId"] }, obj);
            }
            if (this.addUsers.invalid) {
                return;
            }
            // else if (this.selectedRoles == null) {
            //   this.pageType = "ShowAlertPopup";
            // }
            else {
                if (locCount == 0 && !globalAdmin) {
                    this.pageType = "ShowAlertPopup";
                    return;
                }
                else if (this.pageType == "addUserManually") {
                    const users$ = this.globalSetupService.addUserManually(obj);
                    this.loaderService
                        .showLoaderUntilCompleted(users$)
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe((res) => {
                        if (!!res["response"]) {
                            let data;
                            data = {
                                pageType: this.pageType,
                                data: this.data,
                            };
                            if (this.closeDialogAddMoreUsers !=
                                "AddMoreUsers") {
                                this.dialogRef.close(data);
                            }
                            else {
                                if (!this.digitalVersion) {
                                    this.addUsers.controls["userSearch"].setValidators(null);
                                }
                                this.addUsers.reset();
                                this.submittedUsers = false;
                                this.addUsers.clearValidators();
                                this.addUsers.updateValueAndValidity();
                            }
                        }
                    }, (err) => { });
                }
                else if (this.pageType == "editUsers") {
                    const users$ = this.globalSetupService.updateGlobalSetupUsers(obj);
                    this.loaderService
                        .showLoaderUntilCompleted(users$)
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe((res) => {
                        if (!!res["response"]) {
                            let data;
                            data = {
                                pageType: this.pageType,
                                data: this.data,
                            };
                            this.dialogRef.close(data);
                        }
                    }, (err) => { });
                }
            }
        }
    }
    toggleNonTeamsLocations(eve) {
        if (eve.checked)
            this.addLocNonTeams = true;
        else
            this.addLocNonTeams = false;
        this.locationsListNonTeams = [];
    }
    selectDept(dropData) {
        this.dept = dropData;
    }
    submitAddUserNonTeams(previousPageType) {
        this.previousUserPageType = previousPageType;
        this.submittedUsers = true;
        const emojiRegex = /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{1F700}-\u{1F77F}|\u{1F900}-\u{1F9FF}|\u{1F1E6}-\u{1F1FF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}]/gu;
        if (this.addUsersNonTeams.get("employeeId").value && this.addUsersNonTeams.get("employeeId").value.trim().length == 0 || emojiRegex.test(this.addUsersNonTeams.get("employeeId").value)) {
            this.toaster.error("Please enter a valid employee ID");
            return;
        }
        if (this.addUsersNonTeams.get("employeeId").value && this.addUsersNonTeams.get("employeeId").value.trim().length > 30) {
            this.toaster.error("Employee ID cannot exceed 30 characters");
            return;
        }
        if (this.addUsersNonTeams.get("globalAdmin").value) {
            this.locationsListNonTeams = [];
        }
        if (!this.accessAllLocationsNonTeams &&
            !this.addUsersNonTeams.get("globalAdmin").value &&
            this.customLocationList.length == 0) {
            return;
        }
        if (this.dept == null && !this.isTenantEngagement()) {
            return;
        }
        if (this.addUsersNonTeams.controls.errors != null) {
            return false;
        }
        let locCount = 0;
        let loc = this.locationsListNonTeams;
        let rolesToAssign = [];
        loc.forEach((locations) => {
            locations["roles"].forEach((roles) => {
                rolesToAssign.push({
                    roleId: roles.id,
                    zoneIds: [],
                });
            });
            locCount = locCount + 1;
        });
        const uniqueRoles = [
            ...new Map(rolesToAssign.map((item) => [item["roleId"], item])).values(),
        ];
        uniqueRoles.forEach((indRoles) => {
            loc.forEach((locations) => {
                locations["roles"].forEach((roles) => {
                    if (indRoles["roleId"] == roles.id) {
                        indRoles["zoneIds"].push(locations["zoneId"]);
                    }
                });
            });
        });
        let deptId;
        if (this.dept && this.dept.id) {
            deptId = this.dept.id;
        }
        else {
            deptId = "";
        }
        if (deptId == "") {
            deptId = null;
        }
        else {
            deptId = deptId;
        }
        let globalAdmin = this.addUsersNonTeams.get("globalAdmin").value;
        if (!this.accessAllLocationsNonTeams && !globalAdmin) {
            let locObj;
            let zone = [];
            this.customLocationList.forEach((element) => {
                let existLoc = false;
                uniqueRoles.forEach((indRoles) => {
                    indRoles["zoneIds"].forEach((loc) => {
                        if (element["zoneId"] == loc) {
                            existLoc = true;
                            return;
                        }
                    });
                });
                zone.push(element["zoneId"]);
            });
            if (zone.length > 0) {
                locObj = {
                    roleId: "13",
                    zoneIds: zone,
                };
                uniqueRoles.push(locObj);
            }
        }
        let obj;
        obj = {
            allSiteAccess: this.allSiteAccessUsers,
            email: this.addUsersNonTeams.get("email").value,
            firstName: this.addUsersNonTeams.get("employeeName").value,
            password: null,
            lastName: this.addUsersNonTeams.get("lastName").value,
            rolesToAssign: uniqueRoles,
            phoneNum: "",
            tenantId: 1,
            empCode: this.addUsersNonTeams.get("employeeId").value,
            deptId: deptId,
            departName: this.dept && this.dept.name ? this.dept.name : "",
            grade: "MGR",
            shiftStartTime: "0:0",
            shiftEndTime: "23:59",
            supervisorId: "",
            autoAssign: this.autoAssignUsers,
            globalAdmin: globalAdmin,
        };
        if (this.pageType == "addUserManuallyNonTeams") {
            obj = Object.assign({}, obj);
        }
        else if (this.pageType == "editUsersNonTeams") {
            obj = Object.assign({ id: this.data["dialogData"]["editUsers"]["userId"] }, obj);
        }
        if (this.addUsersNonTeams.invalid) {
            return;
        }
        // else if (this.selectedRoles == null) {
        //   this.pageType = "ShowAlertPopup";
        // }
        else {
            if (this.pageType == "addUserManuallyNonTeams") {
                const users$ = this.globalSetupService.addUserManually(obj);
                this.loaderService
                    .showLoaderUntilCompleted(users$)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((res) => {
                    if (!!res["response"]) {
                        let data;
                        this.toaster.success(this.translate.instant("userHasBeenSuccessfullyOnboarded"));
                        data = {
                            pageType: this.pageType,
                            data: this.data,
                        };
                        if (this.closeDialogAddMoreUsers !=
                            "AddMoreUsers") {
                            this.dialogRef.close(data);
                        }
                        else {
                            this.addUsersNonTeams.reset();
                            this.submittedUsers = false;
                            this.addUsersNonTeams.clearValidators();
                            this.addUsersNonTeams.updateValueAndValidity();
                            this.locationsListNonTeams = [];
                            this.customLocationList = [];
                            this.pageType = this.previousUserPageType;
                            this.departName.setValue("");
                        }
                    }
                }, (err) => { });
            }
            else if (this.pageType == "editUsersNonTeams") {
                const users$ = this.globalSetupService.updateGlobalSetupUsersNonTeams(obj);
                this.loaderService
                    .showLoaderUntilCompleted(users$)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((res) => {
                    if (!!res["response"]) {
                        let data;
                        data = {
                            pageType: this.pageType,
                            data: this.data,
                        };
                        this.toaster.success(this.translate.instant("yourChangesHasBeenSavedSuccessfully"));
                        this.dialogRef.close(data);
                    }
                }, (err) => { });
            }
        }
    }
    isTenantEngagement() {
        if (localStorage.getItem('tenant_Engagement') == "true") {
            return true;
        }
        else {
            return false;
        }
    }
    selectLocationNonTeams(selectedLocations) {
        this.locationNonTeams = selectedLocations;
    }
    selectRolesNonTeams(selectedRoles) {
        this.rolesNonTeams = selectedRoles;
    }
    selectCustomLocationNonTeams(selectedLocations) {
        this.customLocationNonTeams = selectedLocations;
    }
    tagRolesLocationsNonTeams() {
        let count = 0;
        let roleExist = false;
        if (this.locationNonTeams == null) {
            this.toaster.error(this.translate.instant("pleaseAddLocation"));
            return;
        }
        if (this.rolesNonTeams == null) {
            this.toaster.error(this.translate.instant("pleaseAddRoles"));
            return;
        }
        if (this.locationsListNonTeams.length > 0) {
            this.locationsListNonTeams.forEach((element) => {
                if (element["zoneId"] == this.locationNonTeams["zoneId"]) {
                    if (element["roles"].length > 0) {
                        element["roles"].forEach((roles) => {
                            if (roles["id"] == this.rolesNonTeams["id"]) {
                                this.toaster.error("Role already added");
                                roleExist = true;
                                count++;
                                return;
                            }
                        });
                        if (!roleExist) {
                            let rolesObj = {
                                id: this.rolesNonTeams["id"],
                                role: this.rolesNonTeams["name"],
                            };
                            element["roles"].push(rolesObj);
                            count++;
                        }
                    }
                    else {
                        let rolesObj = {
                            id: this.rolesNonTeams["id"],
                            role: this.rolesNonTeams["name"],
                        };
                        element["roles"].push(rolesObj);
                        count++;
                    }
                }
            });
            if (count == 0) {
                let roleObj = [
                    {
                        id: this.rolesNonTeams["id"],
                        role: this.rolesNonTeams["name"],
                    },
                ];
                let locObj = {
                    name: this.locationNonTeams["name"],
                    zoneId: this.locationNonTeams["zoneId"],
                    roles: roleObj,
                };
                this.locationsListNonTeams.push(locObj);
            }
        }
        else {
            let roleObj = [
                {
                    id: this.rolesNonTeams["id"],
                    role: this.rolesNonTeams["name"],
                },
            ];
            let locObj = {
                name: this.locationNonTeams["name"],
                zoneId: this.locationNonTeams["zoneId"],
                roles: roleObj,
            };
            this.locationsListNonTeams.push(locObj);
        }
        this.searchLocationNonTeams.setValue("");
        this.searchRoleNonTeams.setValue("");
        this.locationNonTeams = null;
        this.rolesNonTeams = null;
    }
    tagCustomLocationsNonTeams() {
        if (this.customLocationNonTeams == null) {
            this.toaster.error(this.translate.instant("selectOneLocation"));
            return;
        }
        else {
            if (this.customLocationList.length > 0) {
                let exist = false;
                this.customLocationList.forEach((element) => {
                    if (element["zoneId"] ==
                        this.customLocationNonTeams["zoneId"]) {
                        this.toaster.error(this.translate.instant("locationAlreadyAdded"));
                        exist = true;
                        return;
                    }
                });
                if (!exist) {
                    this.customLocationList.push(this.customLocationNonTeams);
                }
            }
            else {
                this.customLocationList.push(this.customLocationNonTeams);
            }
            this.customLocationNonTeams = null;
            this.searchLocation2NonTeams.setValue("");
        }
    }
    showTagLocations(previousPageType, selectedLocations) {
        this.previousUserPageType = previousPageType;
        this.pageType = "tagLocations";
        if (this.selectedloc.value != null) {
            this.locSelected = this.selectedloc.value;
        }
        let locSel = selectedLocations["value"];
        let assignedLocArray = [];
        locSel.forEach((element, idx) => {
            assignedLocArray.push(element["names"]);
        });
        this.selectedloc.setValue(assignedLocArray);
        this.selectedLocations = assignedLocArray;
    }
    removeLocation(i) {
        // remove address from the list
        const control = this.addUsers.controls["locations"];
        control.removeAt(i);
    }
    removeRoles(locIndex, rolesIndex) {
        let loc = this.addUsers.get("locations");
        let role = loc["controls"][locIndex]["controls"]["roles"];
        const roleSelected = role.value;
        if (roleSelected.length == 1) {
            this.disableLocationByIndex = locIndex;
            this.pageType = "ShowAlertRole";
            this.previousPage = "addUserManually";
        }
        else {
            roleSelected.splice(rolesIndex, 1);
            role.updateValueAndValidity();
        }
    }
    removeRolesNonTeams(locIndex, rolesIndex) {
        let loc = this.locationsListNonTeams;
        let role = loc[locIndex]["roles"];
        const roleSelected = role;
        if (roleSelected.length == 1) {
            this.disableLocationByIndex = locIndex;
            let previousPage = this.pageType;
            this.pageType = "ShowAlertRole";
            this.previousPage = previousPage;
        }
        else {
            roleSelected.splice(rolesIndex, 1);
        }
    }
    removeCustomLoc(index, zId) {
        this.customLocationList.splice(index, 1);
        if (this.locationsListNonTeams.length > 0) {
            let indexLoc = this.locationsListNonTeams.findIndex((item) => item.zoneId === zId);
            if (indexLoc > -1) {
                this.locationsListNonTeams.splice(indexLoc, 1);
                this.searchLocationNonTeams.setValue("");
            }
        }
    }
    removeRoleAndDisableLocation() {
        if (this.previousPage == "addUserManuallyNonTeams" ||
            this.previousPage == "editUsersNonTeams") {
            let loc = this.locationsListNonTeams;
            loc.splice(this.disableLocationByIndex, 1);
            this.pageType = this.previousPage;
        }
        else {
            let loc = this.addUsers.get("locations");
            let role = loc["controls"][this.disableLocationByIndex]["controls"]["roles"];
            const roleSelected = role.value;
            roleSelected.splice(0, 1);
            role.updateValueAndValidity();
            this.pageType = "addUserManually";
        }
    }
    selectedLocation() {
        // this.pageType = "addUserManually";
        if (this.previousUserPageType == "addUserManually") {
            this.pageType = "addUserManually";
        }
        else if (this.previousUserPageType == "editUsers") {
            this.pageType = "editUsers";
        }
        const details = this.addUsers.get("locations");
        let alreadySelectedZones = details.value;
        const uniqueAlreadySelectedZoneIds = [
            ...new Set(alreadySelectedZones.map((item) => item.names.zoneId)),
        ];
        const uniqueCheckedZoneIds = [
            ...new Set(this.selectedLocations.map((item) => item.zoneId)),
        ];
        if (alreadySelectedZones.length > 0) {
            this.selectedLocations.forEach((res) => {
                if (!uniqueAlreadySelectedZoneIds.includes(res["zoneId"])) {
                    details.push(this.formBuilder.group({
                        names: new FormControl(res),
                        roles: new FormControl([], Validators.required),
                        toggleControl: new FormControl(true),
                    }));
                }
            });
            uniqueAlreadySelectedZoneIds.forEach((zoneId) => {
                if (!uniqueCheckedZoneIds.includes(zoneId)) {
                    let i = 0;
                    details.value.forEach((res) => {
                        if (res["names"]["zoneId"] == zoneId) {
                            this.removeLocation(i);
                        }
                        i++;
                    });
                }
            });
        }
        else {
            this.selectedLocations.forEach((res) => {
                details.push(this.formBuilder.group({
                    names: new FormControl(res),
                    roles: new FormControl([], Validators.required),
                    toggleControl: new FormControl(true),
                }));
            });
        }
    }
    showRoles(roles, i, previousPageType) {
        this.previousUserPageType = previousPageType;
        this.pageType = "showRoles";
        this.selectedLocIndex = i;
        this.selectedRol.setValue(roles);
        this.selectedRoles = roles;
    }
    skipIntro() {
        this.pageType = "onboardUsersStepThree";
    }
    goBack(alertTypeOnclose) {
        this.pageType = this.previousPage;
    }
    showAddOnDepartment() {
        if (this.digitalVersion && !this.jumpreeProNonTeams) {
            this.skipIntro();
        }
        else if (this.jumpreeProNonTeams &&
            !this.digitalService.nissanDepartment) {
            this.pageType = "openDialogAddDepartment";
        }
        else if (this.digitalService.nissanDepartment) {
            this.pageType = "openDialogNisaanAddDepartment";
        }
        else {
            this.pageType = "openDialogAddDepartment";
        }
    }
    showOnboardUsersAllLocations() {
        this.pageType = "accessToAllUsersLocations";
    }
    showGeneralUserRole(siteAccessBooleanValue) {
        this.emsService.getDigitalSettingsConfig(this.currentZone).subscribe((res) => {
            let resp = res["response"];
            // let resp = "{\"adUrl\": \"\", \"adDomain\": \"\", \"rate_limiter\": {\"rate.limit.per.otp.per.hour\": 0, \"rate.limit.per.minute.per.ip\": 0, \"rate.limit.per.hour.per.username\": 0, \"rate.limit.per.minute.per.upload\": 0, \"rate.limit.per.minute.per.session\": 0, \"rate.limit.per.changepassword.per.hour\": 0, \"rate.limit.per.forgotpassword.per.hour\": 0, \"generic.rate.limit.per.minute.per.upload\": 0}, \"addNewUsersAd\": true, \"oauth.provider\": \"AZURE\", \"onboardedUsers\": \"\", \"phoneMandatory\": false, \"emailListFilter\": \"\", \"exclusionIPList\": \"\", \"login.mechanism\": \"OAUTH\", \"saml_properties\": {\"sp.user.create.if.not.exists\": true, \"sp.user.successful.redirect.url\": \"https://vaccinetesting.smartenspaces.com/spacemanagementV2/#/\"}, \"signupMailSubject\": \"\", \"phonevalidationReq\": \"\", \"callback_properties\": {\"user.update.corp.callback.external.api\": \"\", \"user.update.corp.callback.external.header-keys\": \"\", \"user.update.corp.callback.external.header-values\": \"\"}, \"defaultCountryCodes\": [{\"name\": \"Australia\", \"regionCode\": \"AU\", \"countryCode\": \"+61\"}], \"mapDepartmentToHead\": false, \"okta_oAuth_properties\": {}, \"salesforce_properties\": {}, \"azure_oAuth_properties\": {}, \"loginRemoteLdapEnabled\": \"\", \"google_oAuth_properties\": {}, \"manualAccessAllLocations\": true, \"giveAccessToHeadZoneSites\": false, \"isResponsibilitiesModuleFilter\": false}";
            resp = JSON.parse(resp);
            let digitalSettingsConfigData = resp;
            let ad_Integrations = "false";
            let clonedConfig = Object.assign({}, digitalSettingsConfigData);
            clonedConfig["addNewUsersAd"] = ad_Integrations;
            clonedConfig["manualAccessAllLocations"] = siteAccessBooleanValue ? "true" : "false";
            if (this.jumpreeProNonTeams) {
                this.accessAllLocationsNonTeams = siteAccessBooleanValue
                    ? true
                    : false;
            }
            // clonedConfig['login.mechanism'] = this.settingsForm.value.userLoginMechanism;
            clonedConfig["login.mechanism"] = "DB";
            const currentuserModule$ = this.emsService.postDigitalSettingsConfig(this.currentZone, clonedConfig);
            this.loaderService
                .showLoaderUntilCompleted(currentuserModule$)
                .subscribe((res) => { }, (err) => {
                this.commonService.openSnackBar(err.error.message, "");
            });
        }, (err) => {
            this.commonService.openSnackBar(err.error.message, "");
        });
        this.allSiteAccessUsers = siteAccessBooleanValue;
        let data = {
            update: siteAccessBooleanValue,
        };
        this.pageType = "generalUserRole";
    }
    showSettingsOnboarding() {
        this.pageType = "showSettingsOnboard";
    }
    showAddOnUsers(autoAssign) {
        this.autoAssignUsers = autoAssign;
        if (this.digitalVersion) {
            this.pageType = "proceedGlobal";
        }
        else {
            this.pageType = "addUsers";
        }
    }
    getTranslate(word) {
        let translatedWord = word;
        const test = this.translate.get([word]).subscribe(translation => {
            translatedWord = translation;
        });
        return translatedWord[word];
    }
    addDepartmentManually() {
        this.submittedDepartmentType = true;
        if (this.addDepartmentType == "" && this.submittedDepartmentType) {
            this.toaster.error(this.translate.instant("pleaseSelectOneOption"));
            this.submittedDepartmentType = false;
        }
        if (this.addDepartmentType == "add_manually") {
            this.pageType = "addDepartmentManually";
        }
        if (this.addDepartmentType == "add_excel") {
            this.submitted = true;
            if (this.uploadedFileName != "") {
                this.proceedDepartmentUploadFile();
            }
        }
    }
    addUsersManually(initialUserCountOnLoad) {
        this.usersCountLength = initialUserCountOnLoad;
        this.submittedUserType = true;
        if (this.addUserType == "" && this.submittedUserType) {
            this.toaster.error(this.translate.instant("pleaseSelectOneOption"));
            this.submittedUserType = false;
        }
        if (this.addUserType == "add_manually") {
            this.pageType = "addUserManually";
            this.loadLocation();
            this.loadDepartment();
            this.getRolesList();
            this.getCountryCodes();
        }
        if (this.addUserType == "add_excel") {
            this.submitted = true;
            if (this.uploadedFileName != "") {
                this.proceedUploadFile();
            }
        }
        if (this.addUserType == "add_excel_non_digital") {
            if (this.addViaExcelNew) {
                this.submitted = true;
            }
            this.addViaExcelNew = true;
            if (this.uploadedFileName != "") {
                this.uploadUserBulk();
            }
        }
        if (this.addUserType == "add_manually_non_digital") {
            this.pageType = "addUserManuallyNonTeams";
            this.loadLocation();
            this.loadDepartment();
            this.getRolesList();
            this.getCountryCodes();
        }
    }
    toggleLocation(event, locIndex) {
        let loc = this.addUsers.get("locations");
        let role = loc["controls"][locIndex]["controls"]["roles"];
        if (event.checked) {
            role.setValidators(Validators.required);
        }
        else {
            role.clearValidators();
        }
        role.updateValueAndValidity();
    }
    addMoreUsers(pageType) {
        this.submittedUsers = true;
        if (this.jumpreeProNonTeams) {
            this.closeDialogAddMoreUsers = "AddMoreUsers";
            this.submitAddUserNonTeams(pageType);
        }
        else {
            if (this.addUsers.get("globalAdmin").value) {
                this.addUsers.get("locations").setValue([]);
                this.addUsers.get("locations").setValidators(null);
                this.addUsers.get("locations").updateValueAndValidity();
            }
            if (this.addUsers.invalid) {
                return;
            }
            else {
                this.closeDialogAddMoreUsers = "AddMoreUsers";
                this.showAlertPopup(pageType);
            }
        }
    }
    selectAddLocation() {
        this.pageType = "selectAddLocations";
    }
    showEditGoogleMap() {
        this.pageType = "editLocationsOptions";
    }
    editLocationsManually() {
        this.pageType = "editLocationsManually";
    }
    addLocationOptions() {
        this.submittedLocationType = true;
        if (this.addLocationType == "" && this.submittedLocationType) {
            this.toaster.error(this.translate.instant("pleaseSelectOneOption"));
            this.submittedLocationType = false;
        }
        if (this.addLocationType == "add_manually") {
            this.pageType = "addLocationsOptions";
        }
        if (this.addLocationType == "add_excel") {
            this.submitted = true;
            if (this.uploadedFileName != "") {
                this.proceedLocationUploadFile();
            }
        }
    }
    addLocationsManually() {
        this.pageType = "addLocationsManually";
    }
    selectLocation() {
        this.pageType = "selectLocation";
    }
    sendEmailPopup() {
        this.pageType = "sendEmailPopup";
    }
    resendMail() {
        this.globalSetupService.resendEmail(this.userId).subscribe((res) => {
            if (res) {
                this.toaster.success(this.translate.instant("sucessfullyResentTheMail"));
            }
        }, (err) => {
            this.toaster.error(err.error.message);
        });
    }
    redirectLocation() {
        let pageType = this.pageType;
        this.closePopup(pageType);
    }
    redirectIntro(type) {
        let pageType;
        if (type == "LOCATIONS") {
            pageType = "showLoactionIntro";
        }
        else if (type == "DEPARTMENTS") {
            pageType = "showDepartmentIntro";
        }
        else if (type == "USERS") {
            pageType = "showUsersIntro";
        }
        let data = {
            pageType: pageType,
            type: type,
        };
        this.closePopup(data);
    }
    redirectSettingsIntro() {
        let pageType = "showSettingsIntro";
        this.closePopup(pageType);
    }
    closeGlobalOnboarding() {
        let pageType = "showFinalIntro";
        this.closePopup(pageType);
    }
    uploadCSV(files) {
        this.submitted = false;
        this.templateData = [];
        this.correctData = [];
        this.incorrectData = [];
        // this.uploadedFileList = Array.from(files);
        if (this.isCSVFile(files[0]) || this.isXLSXFile(files[0])) {
            this.fileToUpload = files.item(0);
            if (this.fileToUpload) {
                this.labelImport.nativeElement.innerText = "";
                this.uploadedFileName = Array.from(files)
                    .map((f) => f.name)
                    .join(", ");
            }
            else {
                this.toaster.error(this.translate.instant("noFileSelected"));
                //alert("No file selected!");
            }
        }
        else {
            this.toaster.error(this.translate.instant("pleaseImportValidCsvFile"));
            this.fileReset();
        }
        this.fileUploader.nativeElement.value = null;
    }
    fileReset() {
        this.labelImport.nativeElement.value = "";
    }
    isCSVFile(file) {
        return file.name.endsWith(".csv");
    }
    isXLSXFile(file) {
        return file.name.endsWith(".xlsx");
    }
    downloadTemplate(type) {
        this.loaderService.loadingOn();
        this.templateDownloaded = true;
        this.globalSetupService
            .downloadOnboardUsersTemplate(this.currentZone, type)
            .subscribe((res) => {
            this.loaderService.loadingOff();
            let downloadRes = res["response"];
            this.commonService.downloadCSV(downloadRes);
        }, (err) => {
            this.commonService.downloadCSV(err.error.text);
            this.loaderService.loadingOff();
        });
    }
    downloadUserNonTeam(value) {
        this.loaderService.loadingOn();
        const downloadSub$ = this.globalSetupService
            .downloadUserBulkUploadTemplate(value)
            .subscribe((res) => {
            this.loaderService.loadingOff();
            if (!!res) {
                const fileName = "users.xlsx";
                this.excelService.saveApiResponseToFile(res, fileName);
            }
        }, (err) => {
            this.loaderService.loadingOff();
        });
    }
    proceedDepartmentUploadFile() {
        if (this.fileToUpload) {
            let allocation$;
            if (this.data["dialogData"] &&
                this.data["dialogData"]["showNissan"]) {
                allocation$ = this.globalSetupService.validateOnboardPrimaryDepartmentTemplate(this.fileToUpload);
            }
            else {
                allocation$ = this.globalSetupService.validateOnboardDepartmentTemplate(this.fileToUpload);
            }
            this.loaderService
                .showLoaderUntilCompleted(allocation$)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((data) => {
                this.templateData = [];
                let pagetype;
                if (this.data["dialogData"] &&
                    this.data["dialogData"]["showNissan"]) {
                    if (data["response"]) {
                        if (data["response"] &&
                            data["response"]["failure"] &&
                            data["response"]["failure"].length > 0) {
                            let deptData;
                            console.log(data["response"]["failure"]);
                            data["response"]["failure"].forEach((dept) => {
                                deptData = {
                                    slNo: dept.csvRow.slNo,
                                    primaryDepartmentName: dept.csvRow
                                        .primaryDepartment,
                                    secondaryDepartmentName: dept.csvRow
                                        .secondaryDepartment,
                                    reason: dept.message,
                                };
                                this.incorrectData.push(deptData);
                                this.templateData.push(deptData);
                            });
                        }
                        if (data["response"]["success"].length > 0) {
                            data["response"]["success"].forEach((dept) => {
                                let deptData;
                                deptData = {
                                    message: dept,
                                };
                                this.correctData.push(deptData);
                            });
                        }
                    }
                    pagetype = "addDeptExcelNissan";
                }
                else {
                    if (data["response"]["value"].length > 0) {
                        data["response"]["value"].map((dept) => {
                            let { slNo, departmentName } = dept.csvDto;
                            let deptData = {
                                slNo,
                                departmentName,
                                errorFields: dept.columns,
                                reason: dept.reason,
                            };
                            if (dept.columns.length) {
                                this.incorrectData.push(deptData);
                            }
                            else {
                                this.correctData.push(deptData);
                            }
                            this.templateData.push(deptData);
                        });
                    }
                    pagetype = "addDeptExcel";
                }
                let templateData = {
                    closePopup: true,
                    templateData: this.templateData,
                    correctData: this.correctData,
                    incorrectData: this.incorrectData,
                    uploadedFile: this.fileToUpload,
                    proceedToUpload: true,
                    pageType: pagetype,
                    departmentCount: this.departmentCountForIntroScreen,
                    uploadType: "DEPARTMENTS",
                };
                this.dialogRef.close(templateData);
            }, (error) => {
                if (error.error && error.error.message) {
                    this.toaster.error(error["error"]["message"]);
                }
                else {
                    this.commonService.openSnackBar("Something went wrong!! Please make sure file is in correct format", "");
                }
            });
        }
        else {
            this.toaster.error("No file selected!");
        }
    }
    proceedLocationUploadFile() {
        if (this.fileToUpload) {
            const allocation$ = this.globalSetupService.validateOnboardLocationTemplate(this.fileToUpload);
            this.loaderService
                .showLoaderUntilCompleted(allocation$)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((data) => {
                this.templateData = [];
                if (data["response"]["value"].length > 0) {
                    data["response"]["value"].map((loc) => {
                        let { slNo, locationName, country, state, city, timeZone, } = loc.csvDto;
                        let locData = {
                            slNo,
                            locationName,
                            country,
                            state,
                            city,
                            timeZone,
                            errorFields: loc.columns,
                            reason: loc.reason,
                        };
                        if (loc.columns.length) {
                            this.incorrectData.push(locData);
                        }
                        else {
                            this.correctData.push(locData);
                        }
                        this.templateData.push(locData);
                    });
                }
                let templateData = {
                    closePopup: true,
                    templateData: this.templateData,
                    correctData: this.correctData,
                    incorrectData: this.incorrectData,
                    uploadedFile: this.fileToUpload,
                    proceedToUpload: true,
                    pageType: "addLocExcel",
                };
                this.dialogRef.close(templateData);
            }, (error) => {
                this.toaster.error(this.translate.instant("somethingWentWrongPleaseMakeSureFileIsInCorrectFormat"), "");
            });
        }
        else {
            this.toaster.error(this.translate.instant("noFileSelected"));
        }
    }
    proceedUploadFile() {
        if (this.fileToUpload) {
            let type = this.data["uploadType"];
            const allocation$ = this.globalSetupService.validateOnboardUsersTemplate(this.fileToUpload, type);
            this.loaderService
                .showLoaderUntilCompleted(allocation$)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((data) => {
                if (data["response"]["value"].length > 0) {
                    //TODO
                    if (type == "USERS") {
                        data["response"]["value"].map((user) => {
                            let { slNo, firstName, lastName, email, phone, empCode, departmentName, generalUser, globalAdmin, groups, integrations, people, settings, superAdmin, location, } = user.csvDto;
                            let uploadData = {
                                slNo,
                                firstName,
                                lastName,
                                email,
                                phone,
                                empCode,
                                departmentName,
                                generalUser,
                                globalAdmin,
                                groups,
                                integrations,
                                people,
                                settings,
                                superAdmin,
                                location,
                                errorFields: user.columns,
                                reason: user.reason,
                            };
                            if (user.columns.length) {
                                this.incorrectData.push(uploadData);
                            }
                            else {
                                this.correctData.push(uploadData);
                            }
                            this.templateData.push(uploadData);
                        });
                    }
                    if (type == "DEPARTMENTS") {
                        data["response"]["value"].map((user) => {
                            let { slNo, departmentName } = user.csvDto;
                            let uploadData = {
                                slNo,
                                departmentName,
                                errorFields: user.columns,
                                reason: user.reason,
                            };
                            if (user.columns.length) {
                                this.incorrectData.push(uploadData);
                            }
                            else {
                                this.correctData.push(uploadData);
                            }
                            this.templateData.push(uploadData);
                        });
                    }
                    if (type == "LOCATIONS") {
                        data["response"]["value"].map((user) => {
                            let { slNo, locationName, country, state, city, timeZone, } = user.csvDto;
                            let uploadData = {
                                slNo,
                                locationName,
                                country,
                                state,
                                city,
                                timeZone,
                                errorFields: user.columns,
                                reason: user.reason,
                            };
                            if (user.columns.length) {
                                this.incorrectData.push(uploadData);
                            }
                            else {
                                this.correctData.push(uploadData);
                            }
                            this.templateData.push(uploadData);
                        });
                    }
                }
                let templateData = {
                    closePopup: true,
                    templateData: this.templateData,
                    correctData: this.correctData,
                    incorrectData: this.incorrectData,
                    uploadedFile: this.fileToUpload,
                    proceedToUpload: true,
                    pageType: this.pageType,
                    uploadType: this.data["uploadType"],
                    usersCountLength: this.data["usersCountLength"],
                };
                if (this.addViaExcelNew) {
                    this.userTemplateData = templateData;
                    this.pageType = "showUserData";
                    this.dialogRef.addPanelClass("lg-modal");
                    if (this.userTemplateData &&
                        ((this.userTemplateData["templateData"] &&
                            this.userTemplateData["templateData"]
                                .length) ||
                            (this.userTemplateData["incorrectData"] &&
                                this.userTemplateData["incorrectData"]
                                    .length &&
                                !this.userTemplateData["proceedToUpload"]))) {
                        this.bulkUploadedData = this.userTemplateData["templateData"];
                        this.incorrectData = this.userTemplateData["incorrectData"];
                        this.fileUploaded = this.userTemplateData["uploadedFile"];
                        // this.length1 = res['templateData'] && res['templateData'].length;
                    }
                    if (this.userTemplateData &&
                        this.userTemplateData["proceedToUpload"]) {
                        this.bulkUploadedData = this.userTemplateData["templateData"];
                        this.correctData = this.userTemplateData["correctData"];
                        this.incorrectData = this.userTemplateData["incorrectData"];
                        this.fileUploaded = this.userTemplateData["uploadedFile"];
                        // this.length1 = this.userTemplateData['templateData'] && res['templateData'].length;
                        if (!this.incorrectData.length ||
                            this.userTemplateData["uploadCorrectEntries"]) {
                            this.proceedToUploadBtn = true;
                        }
                    }
                }
                else {
                    this.dialogRef.close(templateData);
                }
            }, (error) => {
                if (error.error && error.error.message) {
                    this.toaster.error(error["error"]["message"]);
                }
                else {
                    this.commonService.openSnackBar("Something went wrong!! Please make sure file is in correct format", "");
                }
            });
        }
        else {
            this.toaster.error(this.translate.instant("noFileSelected"));
        }
    }
    confirmToUploadRemainingEntries() {
        if (this.correctData.length) {
            this.fileType = "USERS";
            this.templateData = this.bulkUploadedData;
            this.correctData = this.correctData;
            this.incorrectData = this.incorrectData;
            this.fileToUpload = this.fileUploaded;
            this.pageType = "incorrectEntries";
            this.displayedColumnsIncorrectData = [
                "slNo",
                "departmentName",
                "reason",
            ];
        }
        else {
            this.toaster.error(this.translate.instant("thereAreNoCorrectEntriesToUpload"));
        }
    }
    reuploadFile() {
        // let type = "";
        // let data = {
        //     type: "uploadCsvWithFixes",
        //     uploadType: 'USERS',
        //     currentZone: this.currentZone,
        //     templateData: this.bulkUploadedData,
        //     incorrectData: this.incorrectData,
        //     fileUploaded: this.fileUploaded,
        // };
        this.fileType = "USERS";
        this.templateData = this.bulkUploadedData;
        this.correctData = this.correctData;
        this.incorrectData = this.incorrectData;
        this.fileToUpload = this.fileUploaded;
        this.pageType = "uploadCsvWithFixes";
        this.data["uploadType"] = "USERS";
    }
    downloadCurrentFile() {
        let downloadArr = [];
        let headers = [];
        let obj = {};
        if (this.data["uploadType"] == "USERS") {
            // obj.slNo = "Serial Number";
            // obj.firstName = "First Name";
            // obj.lastName = "Last Name";
            // obj.email = "Email";
            // obj.phone = "Phone";
            // obj.empCode = "Employee ID";
            // obj.departmentName = "Department";
            // obj.location = "Location";
            // obj.roles = "Roles";
            // obj.errorFields = "Error Fields";
            // obj.reason = "Reason";
            headers = [
                "Serial Number",
                "First Name",
                "Last Name",
                "Email",
                "Phone",
                "Employee ID",
                "Department",
                "Location",
                "Roles",
                "Error Fields",
                "Reason",
            ];
            downloadArr = this.templateData.map((bookingData) => {
                let { slNo, firstName, lastName, email, phone, empCode, departmentName, location, roles, errorFields, reason, } = bookingData;
                return {
                    slNo,
                    firstName,
                    lastName,
                    email: email.toString(),
                    phone,
                    empCode,
                    departmentName,
                    location,
                    roles,
                    errorFields: errorFields.join(" "),
                    reason: reason,
                };
            });
        }
        if (this.data["uploadType"] == "DEPARTMENTS") {
            // obj.slNo = "Serial Number";
            // obj.departmentName = "Department Name";
            // obj.errorFields = "Error Fields";
            // obj.reason = "Reason";
            headers = [
                this.getTranslate("Serial Number"),
                this.getTranslate("Department Name"),
                this.getTranslate("Error Fields"),
                this.getTranslate("Reason"),
            ];
            downloadArr = this.templateData.map((elementData) => {
                let { slNo, departmentName, errorFields, reason } = elementData;
                return {
                    slNo,
                    departmentName,
                    errorFields: errorFields.toString(),
                    reason,
                };
            });
        }
        if (this.data["uploadType"] == "LOCATIONS") {
            // obj.slNo = "Serial Number";
            // obj.locationName = "Location Name";
            // obj.country = "Country";
            // obj.state = "State";
            // obj.city = "City";
            // obj.timeZone = "Time Zone";
            // obj.errorFields = "Error Fields";
            // obj.reason = "Reason";
            headers = [
                this.getTranslate("Serial Number"),
                this.getTranslate("Location Name"),
                this.getTranslate("Country"),
                this.getTranslate("State"),
                this.getTranslate("City"),
                this.getTranslate("Time Zone"),
                this.getTranslate("Error Fields"),
                this.getTranslate("Reason"),
            ];
            downloadArr = this.templateData.map((elementData) => {
                let { slNo, locationName, country, state, city, timeZone, errorFields, reason, } = elementData;
                return {
                    slNo,
                    locationName,
                    country,
                    state,
                    city,
                    timeZone,
                    errorFields: errorFields.toString(),
                    reason,
                };
            });
        }
        console.log(downloadArr);
        console.log(headers);
        // downloadArr.unshift(obj);
        // new Angular5Csv(downloadArr, "download_data");
        const csvContent = this.generateCsvContent(downloadArr, headers);
        this.commonService.downloadCSV(csvContent);
    }
    generateCsvContent(data, head) {
        const csvRows = [];
        const headers = head;
        csvRows.push(headers.join(","));
        for (const item of data) {
            let values = [];
            if (this.data["uploadType"] == "USERS") {
                values = [
                    item.slNo,
                    item.firstName,
                    item.lastName,
                    item.email,
                    item.phone,
                    item.empCode,
                    item.departmentName,
                    item.location,
                    item.roles,
                    item.errorFields,
                    item.reason,
                ]; // Replace with your own fields
            }
            else if (this.data["uploadType"] == "LOCATIONS") {
                values = [
                    item.slNo,
                    item.locationName,
                    item.country,
                    item.state,
                    item.city,
                    item.timeZone,
                    item.errorFields,
                    item.reason,
                ]; // Replace with your own fields
            }
            else if (this.data["uploadType"] == "DEPARTMENTS") {
                values = [
                    item.slNo,
                    item.departmentName,
                    item.errorFields,
                    item.reason,
                ]; // Replace with your own fields
            }
            csvRows.push(values.join(","));
        }
        // Combine rows into a single CSV string
        const csvString = csvRows.join("\n");
        return csvString;
    }
    getToolTipData(hoverData) {
        return hoverData;
    }
    closeConfirmPopup(loadUserOnClosePopup) {
        let data = {
            pageType: "closingPopUpNotIndividual",
            loadUserOnClosePopup: loadUserOnClosePopup,
        };
        this.dialogRef.close(data);
    }
    closeAlertPopup() {
        let data = {
            closePopup: true,
            templateData: this.templateData,
            correctData: this.correctData,
            incorrectData: this.incorrectData,
            uploadedFile: this.fileToUpload,
            uploadCorrectEntries: false,
            proceedToUpload: false,
            pageType: this.pageType,
            uploadType: this.data["fileType"],
        };
        this.dialogRef.close(data);
    }
    proceedToUpload() {
        let data = {
            closePopup: true,
            templateData: this.templateData,
            correctData: this.correctData,
            incorrectData: this.incorrectData,
            uploadedFile: this.fileToUpload,
            uploadCorrectEntries: true,
            proceedToUpload: true,
            pageType: this.pageType,
            uploadType: this.data["fileType"],
        };
        this.dialogRef.close(data);
    }
    hasValidRoles(loc) {
        return loc.roles.some(role => role.role !== 'APP USER' && role.role !== 'GENERAL_USER');
    }
    selectTimeZone(timeZ) {
        this.addLocationsManuallyForm.get("timezone").setValue(timeZ);
    }
    selectedRole() {
        // this.pageType = "addUserManually";
        if (this.previousUserPageType == "addUserManually") {
            this.pageType = "addUserManually";
        }
        else if (this.previousUserPageType == "editUsers") {
            this.pageType = "editUsers";
        }
        let loc = this.addUsers.get("locations");
        let role = loc["controls"][this.selectedLocIndex].get("roles");
        role.setValue(this.selectedRoles);
    }
    goToRoles() {
        this.pageType = "viewRoles";
        this.dialogRef.removePanelClass("md-modal");
        this.dialogRef.addPanelClass("lg-modal");
    }
    goToUsers() {
        this.pageType = "generalUserRole";
        this.dialogRef.removePanelClass("lg-modal");
        this.dialogRef.addPanelClass("md-modal");
    }
    proceedToPeopleTab() {
        if (this.jumpreeProNonTeams) {
            this.pageType = "addUsersNew";
        }
        else {
            this.closePopup("peopleTab");
        }
    }
    showUserDataBackBtn() {
        this.pageType = "addUsersNew";
        this.dialogRef.updateSize("45%");
        this.dialogRef.removePanelClass("lg-modal");
        this.dialogRef.addPanelClass("md-modal");
    }
    uploadUserBulk() {
        const validateCSVOnboarding$ = this.globalSetupService.validateExcelUser(this.fileToUpload);
        this.loaderService
            .showLoaderUntilCompleted(validateCSVOnboarding$)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => {
            this.pageType = "showUserData";
            this.dialogRef.updateSize("75%");
            this.dialogRef.addPanelClass("lg-modal");
            if (!!res["response"]) {
                this.userExcelResponse = res["response"];
                if (res["response"]["value"].length > 0) {
                    this.incorrectData = [];
                    this.correctData = [];
                    this.saveCorrectData = [];
                    res["response"]["value"].map((user) => {
                        let { slNo, firstName, lastName, email, department, globalAdmin, location, superAdmin, peopleAdmin, bookingAdmin, integrationsAdmin, } = user.csvDto;
                        let uploadData = {
                            slNo,
                            firstName,
                            lastName,
                            email,
                            department,
                            globalAdmin,
                            location,
                            superAdmin,
                            peopleAdmin,
                            bookingAdmin,
                            integrationsAdmin,
                            errorFields: user.columns,
                            reason: user.reason,
                        };
                        if (user.columns.length) {
                            this.incorrectData.push(uploadData);
                        }
                        else {
                            this.correctData.push(uploadData);
                            this.saveCorrectData.push(user.csvDto);
                        }
                        this.templateData.push(uploadData);
                    });
                    this.noOfEntitiesUploaded = this.saveCorrectData.length;
                    if (this.incorrectData.length == 0) {
                        this.dialogRef.updateSize("45%");
                        this.dialogRef.removePanelClass("lg-modal");
                        this.dialogRef.addPanelClass("md-modal");
                    }
                    else {
                        this.totalIncorrectLength = this.incorrectData.length;
                        this.incorrectDataToViewInTable = this.incorrectData.map((user) => {
                            const fullName = user["firstName"] +
                                " " +
                                user["lastName"];
                            let additionalRoles = "";
                            if (user["superAdmin"] == "YES") {
                                additionalRoles = "Super Admin";
                            }
                            else {
                                additionalRoles +=
                                    user["superAdmin"] == "NO"
                                        ? ""
                                        : user["superAdmin"] + ", ";
                                additionalRoles +=
                                    user["peopleAdmin"] == "YES"
                                        ? "People Admin, "
                                        : user["peopleAdmin"] ==
                                            "NO"
                                            ? ""
                                            : user["peopleAdmin"] +
                                                ", ";
                                additionalRoles +=
                                    user["bookingAdmin"] == "YES"
                                        ? "Booking Admin, "
                                        : user["bookingAdmin"] ==
                                            "NO"
                                            ? ""
                                            : user["bookingAdmin"] +
                                                ", ";
                                additionalRoles +=
                                    user["integrationsAdmin"] ==
                                        "YES"
                                        ? "Integration Admin"
                                        : user["integrationsAdmin"] == "NO"
                                            ? ""
                                            : user["integrationsAdmin"];
                                if (additionalRoles == "" &&
                                    user["globalAdmin"] != "YES") {
                                    additionalRoles =
                                        "General User";
                                }
                                additionalRoles = additionalRoles.replace(/[,\s]+$/, "");
                            }
                            let errorFields = user["errorFields"];
                            if (errorFields.indexOf("Booking Admin") !== -1 ||
                                errorFields.indexOf("People Admin") !== -1 ||
                                errorFields.indexOf("Super Admin") !== -1 ||
                                errorFields.indexOf("Integrations Admin") !== -1) {
                                errorFields.push("additionalRole");
                            }
                            return {
                                employeeName: fullName,
                                department: user["department"],
                                email: user["email"],
                                globalAdmin: user["globalAdmin"],
                                location: user["location"],
                                additionalRole: additionalRoles,
                                reason: user["reason"],
                                errorFields: user["errorFields"],
                            };
                        });
                        this.showIncorrectData = this.incorrectDataToViewInTable.slice(0, this.pageSize);
                    }
                }
            }
        }, (error) => {
            this.commonService.openSnackBar(this.translate.instant("somethingWentWrongPleaseMakeSureFileIsInCorrectFormat"), "");
        });
    }
    getNextRecords(event) {
        this.pageSize = event["pageSize"];
        const startIndex = this.pageSize * event["pageIndex"];
        const endIndex = this.pageSize * (event["pageIndex"] + 1);
        this.showIncorrectData = this.incorrectDataToViewInTable.slice(startIndex, endIndex);
    }
    reUploadUsers() {
        this.pageType = "userBulkUpload_reUploadExcel";
        this.dialogRef.updateSize("45%");
        this.dialogRef.removePanelClass("lg-modal");
        this.dialogRef.addPanelClass("md-modal");
        this.incorrectData = [];
        this.correctData = [];
        this.incorrectDataToViewInTable = [];
        this.showIncorrectData = [];
        this.fileToUpload = undefined;
        this.uploadedFileName = undefined;
    }
    downloadCurrentExcelFile() {
        let incorrectDatas = [];
        let correctDatas = [];
        this.userExcelResponse["value"].map((user) => {
            if (user.columns.length) {
                incorrectDatas.push(user);
            }
            else {
                correctDatas.push(user);
            }
        });
        incorrectDatas.push(...correctDatas);
        const dataToSave = {
            value: incorrectDatas,
            url: this.userExcelResponse["url"],
        };
        this.downloadUserNonTeam(dataToSave);
    }
    alertYes() {
        this.dialogRef.close('yes');
    }
    alertNo() {
        this.dialogRef.close('no');
    }
    proceedToSaveUserDatas() {
        if (this.saveCorrectData.length > 0) {
            const validateCSVOnboarding$ = this.globalSetupService.saveBulkUploadUserData(this.saveCorrectData);
            this.loaderService
                .showLoaderUntilCompleted(validateCSVOnboarding$)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res) => {
                this.closeConfirmPopup("yes");
                this.toaster.success(res["message"]);
            }, (err) => {
                this.toaster.error(this.translate.instant("somethingWentWrong"));
            });
        }
        else {
            this.toaster.error(this.translate.instant("noValidUserFound"));
        }
    }
    onSubmitDisableUser() {
        if (this.selectDisableUser) {
            let data;
            if (this.selectDisableUser == 1) {
                const peopleLoad$ = this.onboardingService.enableOrDisablePeople(this.currentZone, this.data.userObj);
                this.loaderService.showLoaderUntilCompleted(peopleLoad$)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(res => {
                    let status;
                    if (res) {
                        status = true;
                        const updateDisableYes$ = this.onboardingService.deleteFutureBookings(this.currentZone, this.data.userId);
                        const updateDisableYesSpace$ = this.onboardingService.deleteFutureBookingspacemanagement(this.currentZone, this.data.userId);
                        this.loaderService.showLoaderUntilCompleted(forkJoin([updateDisableYes$, updateDisableYesSpace$]))
                            .subscribe(res => {
                        }, error => {
                            if (!error && !!error['error']['message']) {
                                this.toaster.error(error['error']['message']);
                            }
                        });
                        this.dialogRef.close();
                    }
                }, err => {
                });
            }
            else if (this.selectDisableUser == 2) {
                const peopleLoad$ = this.onboardingService.enableOrDisablePeople(this.currentZone, this.data.userObj);
                this.loaderService.showLoaderUntilCompleted(peopleLoad$)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(res => {
                    this.dialogRef.close();
                });
            }
        }
    }
}
