<div class="page-style">
    <app-modal-loader [loading]="loading"></app-modal-loader>
    <!--otp-->
    <ng-container *ngIf="pageType=='verifyOtp'">
    <mat-card>
      <mat-card-title>
        {{'verifyOTP' | translate}}
        <i
          class="fa fa-window-close"
          style="float:right"
          aria-hidden="true"
          (click)="onCloseEventModal()"
        ></i>
      </mat-card-title>
      <hr />
      <form
        class="form"
        name="form"
        (ngSubmit)="f.form.valid"
        #f="ngForm"
        novalidate
      >   
        <mat-card-content>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                  <div>
                    <br />
                    <p>{{'pleaseEnterOneTimePasswordThatHasBeenSentToYour' | translate}}</p>
                    <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:6,isPasswordInput:true,allowNumbersOnly:true}"></ng-otp-input>
                  </div>
                  
                  <div *ngIf="errorOtp">
                    <p class="text-danger">{{'pleaseEnterOneTimePassword' | translate}}</p>
                  </div>
                  <div>
                    <br />                
                    <button type="button" class="btn btn-primary"
                      (click)="confirmOtp()">{{'submit' | translate}}</button>
                  </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </form>
    </mat-card>
  </ng-container>

  <ng-container *ngIf="pageType=='language'">
    <mat-card>
      <div class="modal-header pl-0 pb-2 pt-2 pr-0">
   
        <!-- {{'selectLanguage' | translate}}
        <i
          class="fa fa-window-close"
        
          aria-hidden="true"
          (click)="onlangaugeCloseEventModal()"
        ></i> -->

        <h6 class="semi-bold-blue">   {{'selectLanguage' | translate}}</h6>
        <button
          type="button"
          style="float:right"
          class="close"
          aria-label="Close"
          (click)="onlangaugeCloseEventModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      
 
      </div>
   
      <form class="pt-3" >   
        <mat-card-content>
          <div class="row">
            <div class="col-md-12">
                    <div class="form-group">
                      <label for="crmPOCName"
                          >{{ "select" | translate }}
                          {{ "language" | translate }}</label
                      >
                      <select id="user_name"
                            name="langauge"
                            class="form-control"
                            [(ngModel)]="selectLanguage"
                            placeholder="Enter domain Name"
                      >
                      <option value="">{{"selectLanguage" | translate}}</option>
                          <option
                              *ngFor="let language of selectLanguageList"
                              [value]="language.locale"
                              >{{ language.language }}</option
                          >
                      </select>
                  </div>
                  <div>
                    <br />                
                    <button type="button" class="btn btn-primary loginBtn "
                      (click)="languageSubit()">{{'submit' | translate}}</button>
                  </div>
              </div>
          </div>
        </mat-card-content>
      </form>
    </mat-card>
  </ng-container>
  </div>  